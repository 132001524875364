@import "../../../styles/variables.scss";

body.dashboard {
  background-color: $color-light-grey;


  .stepper-wrapper {
    background-color: #f8f9ff;
    padding: 24px 0px;

    // & > .MuiStepper-root {
    //     max-width: 1200px;
    // }
  }

  .MuiStepLabel-label {
    font-size: 2rem !important;
    font-family: $font-family; // TODO: change to HALCOM
    @media only screen and (max-width: $screenSize-smartphone) {
      font-size: 1.25rem !important;
    }
    &.Mui-disabled {
      color: #657df1 !important;
    }
  }

  .MuiStepConnector-root {
    top: 17px !important;
  }

  .MuiStepIcon-root {
    font-size: 3rem !important;
    border-radius: 20px;

    &:not(.Mui-completed) {
      color: transparent !important;
      border: 2px solid #657df1;
    }

    &.Mui-completed {
      color: #203864 !important;
      border: 2px solid #203864;
    }

    &.Mui-active {
      border-color: #203864 !important;

      & > .MuiStepIcon-text {
        fill: #203864 !important;
      }
    }

    & > .MuiStepIcon-text {
      font-size: 1.2rem;
      font-family: $font-family !important;
      fill: #657df1 !important;
    }
  }

  .Mui-disabled {
    & > .MuiStepConnector-line {
      border-color: #657df1 !important;
    }
  }

  .Mui-active {
    & > .MuiStepConnector-line {
      border-color: #203864 !important;
    }
  }

  .step-wrapper {
    padding: 4rem;

    @media only screen and (max-width: $screenSize-smartphone) {
      padding: 2rem 0 8rem;
    }
  }

  .MuiTooltip-popper > div {
    font-size: 14px !important;
    font-family: $font-family;
    background-color: #203864ee;
    border-radius: 10px;
  }

  & > #root > .App > .siteHeader {
    background-color: $color-light-grey;
  }

  main.dashboard {
    width: 100%;
    max-width: $containerMaxWidth;
    padding-left: $defaultSectionWhitespace;
    padding-right: $defaultSectionWhitespace;
    padding-bottom: 8rem;
    min-height: calc(100vh - 18.9rem);
    box-sizing: border-box;
    margin: 4rem auto 0;

    // display: flex;
    // flex-direction: column;
    // align-items: stretch;
    // justify-content: flex-start;

    @media only screen and (max-width: $screenSize-720p) {
      padding-left: $smallSectionWhitespace;
      padding-right: $smallSectionWhitespace;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      // margin-top: unset;
    }

    .banner {
      position: relative;
      min-height: 32rem;
      background-color: $color-blue;
      color: $color-white;
      margin-bottom: 10rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: $screenSize-mac14) {
        padding: 3rem;
        min-height: 24rem;
      }

      // @media only screen and (max-width: $screenSize-720p) {
      //   min-height: unset;
      // }

      @media only screen and (max-width: $screenSize-smartphone) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 6rem;
        min-height: 37rem;
      }

      &__message {
        font-size: 3rem;
        font-weight: 500;

        @media only screen and (max-width: $screenSize-mac14) {
          font-size: $fs-medium;
        }

        @media only screen and (max-width: $screenSize-720p) {
          font-size: 2rem;
        }
      }

      & > img.visual {
        width: 49rem;
        height: auto;
        margin-right: 3.4vw;

        @media only screen and (max-width: $screenSize-mac14) {
          width: 25.5vw;
          margin-right: 0;
          margin-left: 3rem;
        }

        @media only screen and (max-width: $screenSize-smartphone) {
          margin-top: 3rem;
          margin-left: 0;
          width: 100%;
          align-self: center;
        }
      }

      & > .decorations {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        // z-index: 0;
        border-radius: inherit;
        overflow: hidden;

        & .half {
          overflow: hidden;
          border-radius: unset;
        }

        & .circle {
          color: transparent;
          border-radius: 50%;
          background-color: currentColor;

          &.light-blue {
            color: $color-light-blue;
          }

          &.yellow {
            color: $color-yellow;
          }

          &.blue {
            color: $color-blue;
          }

          &.black {
            color: $color-black;
          }

          &.outline {
            border: 0.3rem solid currentColor;
            background-color: unset;
          }
        }

        & .half {
          overflow: hidden;
          border-radius: unset;
        }

        & > * {
          max-width: 100%;
          overflow: hidden;
          position: absolute;
        }

        & > .d1 {
          width: 1.2rem;
          height: 1.2rem;
          top: 28.6rem;
          left: 13.3rem;

          @media only screen and (max-width: $screenSize-smartphone) {
            top: unset;
            bottom: 3.2rem;
            left: 3rem;
          }
        }

        & > .d2 {
          width: 2.1rem;
          height: 2.1rem;
          top: 3.2em;
          left: 22.4rem;

          @media only screen and (max-width: $screenSize-smartphone) {
            top: 1.3rem;
            left: 2.4rem;
            width: 1.3rem;
            height: 1.3rem;

            &.outline {
              border-width: 0.1rem;
            }
          }
        }

        & > .d3 {
          width: 1.2rem;
          height: 1.2rem;
          top: 4.2rem;
          left: 56.8rem;
        }

        & > .d4 {
          width: 3.1rem;
          height: 3.1rem;
          top: -1.6rem;
          left: 75.7rem;
        }

        & > .d5 {
          width: 3.8rem;
          height: 3.8rem;
          bottom: -1.9rem;
          left: 79.5rem;

          @media only screen and (max-width: $screenSize-smartphone) {
            top: unset;
            bottom: -1.4rem;
            left: unset;
            right: 8.7rem;
            width: 2.6rem;
            height: 2.6rem;
          }
        }

        & > .d6 {
          width: 1.2rem;
          height: 1.2rem;
          top: 4.7rem;
          left: 95.9rem;
        }

        & > .d7 {
          width: 2.7rem;
          height: 2.7rem;
          top: 22.4rem;
          left: 163.4rem;

          @media only screen and (max-width: $screenSize-smartphone) {
            top: 15.7rem;
            left: 40%;
            width: 1.3rem;
            height: 1.3rem;

            &.outline {
              border-width: 0.1rem;
            }
          }
        }
      }
    }

    &.declied .banner &__message {
      text-align: center;
    }

    h1 {
      text-align: center;
      font-size: $fs-large;
      font-weight: 500;
      margin-top: unset;
      margin-bottom: 5rem;

      @media only screen and (max-width: $screenSize-smartphone) {
        font-size: 3rem;
        margin-bottom: 3rem;
      }
    }

    .box.body {
      padding: 3rem;
      background-color: $color-white;
      border-radius: 5rem;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;

      @media only screen and (max-width: $screenSize-smartphone) {
        flex-direction: column;
        border-radius: 4rem;
        padding: $smallSectionWhitespace;
      }

      & > .left {
        width: 45rem;
        max-width: 100%;
        margin-right: 5rem;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;

        @media only screen and (max-width: $screenSize-smartphone) {
          width: 100%;
          margin-bottom: 3rem;
        }

        & > .box {
          background-color: $color-light-blue;
          color: $color-white;
          border-radius: 5rem;
          box-shadow: none;

          // flex-basis: 50%;
          flex-basis: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          @media only screen and (max-width: $screenSize-smartphone) {
            border-radius: 4rem;
          }

          & > h2 {
            font-weight: 400;
            font-size: 1.8rem;
            margin-bottom: $fs-normal;

            @media only screen and (max-width: $screenSize-smartphone) {
              font-size: $fs-normal;
            }
          }

          & > h3 {
            font-weight: 500;
            font-size: 3rem;

            @media only screen and (max-width: $screenSize-smartphone) {
              font-size: 2rem;
            }
          }

          &.amount {
            padding: 4rem;
            color: $color-white;
            margin-bottom: $fs-normal;
          }

          // &.starting-salary {

          // }
       
        }
      }

      & > .right {
        flex-grow: 1;
        box-shadow: none;
        padding: 0;

        & > .table {
          height: 100%;
          width: 100%;

          & > .row {
            padding: 1.6rem 0 1.5rem;
            border-bottom: 1px solid $color-light-grey-2;
          }

          & > .footer {
            margin-top: 4rem;
            font-size: $fs-normal;

            & > .text {
              max-width: 60%;
              color: $color-grey-6;
            }

            @media only screen and (max-width: $screenSize-smartphone) {
              flex-direction: column;
              align-items: stretch;
              justify-content: stretch;

              & > .text {
                margin-bottom: 3rem;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }

  main.dashboard.declined,
  main.dashboard.no-application {
    & > .banner {
      align-items: center;
      justify-content: center;
      margin-bottom: 6rem;

      & > .banner__message {
        text-align: center;
      }
    }

    h1 {
      margin-bottom: 1rem;
      padding-left: 4.5rem;
      padding-right: 4.5rem;
    }

    button.primary {
      min-width: 27.4rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      & > .banner {
        & > .decorations {
          & > .d1 {
            top: unset;
            bottom: 11.2rem;
            left: 5.6rem;
          }

          & .d3 {
            width: 0.7rem;
            height: 0.7rem;
            top: 8.3rem;
            left: 24%;
          }

          & > .d4 {
            width: 0.9rem;
            height: 0.9rem;
            top: 7.4rem;
            right: 3rem;
            left: unset;
          }

          & > .d7 {
            top: 27rem;
            left: unset;
            width: 1.3rem;
            right: 5.4rem;
            height: 1.3rem;
          }
        }
      }

      h1 {
        font-size: 2rem;
        padding: 0 4.5rem;
        margin-bottom: 1rem;
      }

      p {
        font-size: $fs-normal;
        font-weight: 400;
        text-align: center;
        padding: 0 2rem;
      }

      button.primary {
        width: 100%;
      }
    }
  }

  .icon-container {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .value {
    font-size: 1.5rem;
  }

  .funding-information-header {
    width: 100%;

    & > h2 {
      font-weight: 500 !important;
      margin-bottom: 1rem !important;
      text-align: center !important;
    }
  }
}
