@import "../../../styles/variables.scss";

.card__container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.card {
  border-radius: 30px !important;
  width: calc(25% - 25px);
  position: relative;
  border: 1px solid $color-grey;
  box-shadow: none !important;
  @media only screen and (max-width: $screenSize-mac13) {
    width: calc(33.3% - 22px);
  }
  @media only screen and (max-width: $screenSize-smartphone) {
    width: calc(50% - 20px);
  }
  @media only screen and (max-width: 560px) {
    width: 100%;
  }
  h2 {
    color: $color-blue;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 80px;
    @media screen and (max-width: $screenSize-iphone13ProMax) {
      height: auto;
    }
    a {
      color: $color-blue !important;
    }
  }
  p {
    color: $color-blue;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  a {
    text-decoration: none !important;
  }
  .card__chip {
    position: absolute;
    top: 28px;
    left: 28px;
  }
}
.card__date-time {
  color: $color-blue;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0.6;
  .card__date, .card__time {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
}
.card__image {
  display: block;
  padding: 15px;
  img {
    border-radius: 20px;
    width: 100%;
    height: 150px;
    object-fit: cover;
    @media screen and (min-width: $screenSize-mac13) {
      max-height: 12vw;
    }
    @media screen and (min-width: $screenSize-1080p-plus) {
      max-height: 8vw;
    }
  }
}
.card__content {
  padding: 16px 16px 60px !important;
}
.card__actions {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 15px 25px !important;
  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    background: url(../../../assets/icons/arrow_forward.svg) no-repeat;
    width: 13px;
    height: 13px;
    margin-left: 10px;
    margin-top: 5px;
    transition: transform 0.3s ease;
  }
  &:hover:after {
    transform: translateX(5px);
  }
}