@import "../../../styles/variables.scss";

.App .section--importantPoints {
  & > .section__innerWrapper {
    background-color: $color-blue;
    padding: 10rem;

    @media only screen and (max-width: $screenSize-tablet) {
      padding-left: 6.5rem;
      padding-right: 6.5rem;
    }

    // onScreen animations
    & > ul > li.animateIn {
      visibility: hidden;
    }

    & > ul {
      max-width: 150rem; //$centeredInnerMaxWidth;
      width: 150rem; //$centeredInnerMaxWidth;

      margin: 0 auto 2.5rem;
      list-style: none;
      padding: unset;

      // display: grid;
      // grid-template-columns: auto auto auto;
      // justify-items: start;
      // justify-content: space-between;
      // column-gap: 19.2rem;
      // row-gap: 8rem;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;

      @media only screen and (max-width: $screenSize-mac14) {
        max-width: 100%;

        // column-gap: 15vw;
      }

      @media only screen and (max-width: $screenSize-tablet) {
        grid-template-columns: auto auto;
        justify-content: flex-start;

        // column-gap: 10vw;
      }

      @media only screen and (max-width: $screenSize-smartphone) {
        grid-template-columns: auto;
        justify-content: center;
        justify-items: center;
      }

      li {
        text-align: left;
        color: $color-white;
        line-height: 1.5;

        // margin: 4rem 9.6rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
        padding-left: 9.6rem;
        padding-right: 9.6rem;
        box-sizing: border-box;
        width: 33.333%;
        flex-grow: 0;
        flex-shrink: 0;

        @media only screen and (max-width: $screenSize-mac14) {
          padding-left: 7.5rem;
          padding-right: 7.5rem;
        }

        @media only screen and (max-width: $screenSize-tablet) {
          width: 50%;
          padding-left: 5rem;
          padding-right: 5rem;
        }

        @media only screen and (max-width: $screenSize-smartphone) {
          text-align: center;
          width: 100%;
          padding: 0;
        }

        // display: flex;
        // flex-direction: row;

        /* Dealing with 2 orphan items */

        i {
          &.icon {
            // width: 7rem;
            // height: 7rem;
            display: block;
            margin: 0 auto 1.6rem;

            // &.icon--income-driven {
            //   background: url("./assets/images/bullets__icon1.svg");
            // }

            // &.icon--affordable {
            //   background: url("./assets/images/bullets__icon2.svg");
            // }

            // &.icon--no-cosigners {
            //   background: url("./assets/images/bullets__icon3.svg");
            // }

            // &.icon--payment-capped {
            //   background: url("./assets/images/bullets__icon4.svg");
            // }

            // &.icon--no-scredit-score {
            //   background: url("./assets/images/bullets__icon5.svg");
            // }

            // &.icon--no-hidden-fees {
            //   background: url("./assets/images/bullets__icon5.svg");
            // }
         
          }
        }

        & > h3 {
          font-size: 2.4rem;
          font-weight: 500;
          margin-bottom: 1.2rem;
        }

        & > p {
          font-size: 1.6rem;
          font-weight: 400;
        }
      }
    }
  }

  &.onScreen .section__innerWrapper ul > li.animateIn {
    animation: fadeInSlideInFromBottom $animationDuration-short ease-in-out;
    animation-fill-mode: forwards;

    &:nth-of-type(1) {
      animation-delay: 0.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.15s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(4) {
      animation-delay: 0.25s;
    }

    &:nth-of-type(5) {
      animation-delay: 0.3s;
    }

    &:nth-of-type(6) {
      animation-delay: 0.35s;
    }
  }

  .points-header {
    display: block;
    margin: 0 auto 2.5rem;
    text-align: center;
    color: $color-white;
  }
}
