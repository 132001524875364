@import "../../../styles/variables.scss";

.App .section--applyNow {
  & > .section__innerWrapper {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
    background-color: $color-blue;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $screenSize-smartphone) {
      padding-top: 6rem;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  // onScreen animations
  & .animateIn {
    visibility: hidden;
  }

  &.onScreen .animateIn {
    animation: fadeInSlideInFromBottom 0.4s ease-in-out;
    animation-fill-mode: forwards;
  }

  article h2.animateIn,
  article p.animateIn {
    visibility: hidden;
  }

  &.onScreen .section__innerWrapper article h2.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-1 forwards fadeInSlideInFromBottom;
  }

  &.onScreen .section__innerWrapper article p.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-2 forwards fadeInSlideInFromBottom;
  }

  article {
    color: $color-white;
    text-shadow: $textShadow;
    text-align: left;
    max-width: 56rem; //76rem;

    margin-right: 5rem;

    @media only screen and (max-width: $screenSize-tablet) {
      margin-right: 2rem;
    }

    h2 {
      font-size: 4rem;
      font-weight: 600;
      color: inherit;
      text-align: inherit;
    }

    p {
      max-width: 35.5rem;
      font-size: 1.6rem;
      font-weight: 500;
      color: inherit;
      text-align: inherit;
    }
  }

  .formBox {
    width: 69.5rem;
    box-sizing: border-box;
    padding: 5rem;
    background-color: $color-white;
    border-radius: 3rem;
    box-shadow: $box-shadow;

    @media only screen and (max-width: $screenSize-tablet) {
      padding: 1.6rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      margin-top: 4rem;
      width: 100%;
    }

    & > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-size: 2.4rem;
        font-weight: 400;
        color: $color-black;
        text-align: center;
        margin-bottom: 3rem;
        text-align: left;

        span {
          color: $color-light-blue;
          font-size: 3rem;
          white-space: nowrap;
        }
      }
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      .formRow:last-of-type {
        min-height: unset;
      }
    }

    input[type="submit"] {
      width: 100%;
      border: unset;
      margin: unset;
      margin-top: 1rem;
      padding: unset;
      background-color: $color-yellow;
      color: $color-blue;
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: 0.04em;
      text-align: center;
      cursor: pointer;
    }

    .grecaptcha-badge { 
      visibility: hidden;
    }
  }
}
