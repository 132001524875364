@import "../../../styles/variables.scss";

.App .section--calculator {
  margin-top: 10rem; //33rem;

  @media only screen and (max-width: $screenSize-smartphone) {
    margin-top: 8rem; //44rem;
  }

  & > .section__innerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  h4 {
    color: $color-blue;
    text-align: center;
  }

  article h2.animateIn,
  article h3.animateIn,
  .calculatorTool.animateIn {
    visibility: hidden;
  }

  &.onScreen .section__innerWrapper article h2.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-1 forwards fadeInSlideInFromBottom;
  }

  &.onScreen .section__innerWrapper article h3.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-2 forwards fadeInSlideInFromBottom;
  }

  &.onScreen .section__innerWrapper .calculatorTool.animateIn {
    animation: $animationDuration-medium ease-in-out $animateIn-Delay-3 forwards fadeInSlideInFromBottom;
  }

  .calculatorTool {
    position: relative;
    margin: 7rem auto;

    // padding-bottom: 5rem;
    padding: 2.4rem;
    overflow: hidden;
    width: $centeredInnerMaxWidth;
    max-width: 100%;

    // height: 33rem;

    box-sizing: border-box;
    background-color: $color-white;
    box-shadow: $box-shadow;
    border-radius: 5rem;
    color: $color-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .step1 {
      height: 28rem;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      // padding: 2.4rem;

      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;

      @media only screen and (max-width: $screenSize-smartphone) {
        // padding: 0 2.4rem;
        // margin-top: 4rem;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
      }

      &__slider-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: $color-light-grey;
        border-radius: 5rem;
        min-height: 33rem;
        padding: 0 0 0 4rem;

        // &:nth-child(1) {
        margin-bottom: 2.4rem;

        // }

        @media only screen and (max-width: $screenSize-smartphone) {
          padding: 4rem 2.4rem;

          // margin-top: 4rem;
          flex-direction: column;
          align-items: stretch;
          justify-content: space-between;
          border-radius: 4rem;
        }
      }

      // &__slider-wrapper:nth-child(1) {
      //   & > .left {
      //     margin-top: 6rem;
      //   }

      //   & > .right {
      //     margin-top: 6rem;
      //   }
      // }

      .left {
        flex-grow: 1;
        margin-bottom: 6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        .hint {
          font-size: 2.4rem;
          margin-bottom: 3rem;
          display: block;

          @media only screen and (max-width: $screenSize-smartphone) {
            text-align: center;
            font-size: 2rem;
          }
        }

        .MuiSlider-rail,
        .MuiSlider-track {
          height: 1rem;
        }

        & > .sliderMinMaxHint {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          color: $color-grey-7;
          -webkit-user-select: none;
          user-select: none;

          @media only screen and (max-width: $screenSize-smartphone) {
            font-size: $fs-small;
          }
        }
      }

      .right {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34rem;
        height: 25rem; //33rem;

        @media only screen and (max-width: $screenSize-smartphone) {
          width: 100%;

          // height: 30rem;
        }

        .rangeSliderValue {
          font-size: 2.4rem;
          font-family: "Halcom W00 Medium", "League Spartan", sans-serif !important;
          font-weight: 500;
          color: $color-white;
          z-index: 1;
        }

        .decorationIntersect {
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          z-index: 0;

          @media only screen and (max-width: $screenSize-smartphone) {
            width: 24rem;
            height: 24rem;
          }

          .yellow,
          .blue,
          .lightblue {
            position: absolute;
            border-radius: 50%;
            display: block;
          }

          .yellow {
            top: -27.8rem; //-23.8rem;
            right: -23.9rem;
            width: 41.1rem;
            height: 41.1rem;
            background-color: $color-yellow;
            overflow: hidden;
            z-index: 1;

            @media only screen and (max-width: $screenSize-smartphone) {
              top: 13.2rem;
              right: -5rem;
              width: 9.4rem;
              height: 9.4rem;
            }
          }

          .blue {
            top: 40.3rem;
            right: 40.85rem;
            width: 12rem;
            height: 12rem;
            background-color: $color-blue;

            @media only screen and (max-width: $screenSize-smartphone) {
              top: -1.2rem;
              right: 17rem;
            }
          }

          // increasing the width & height of this element should always be in sync with the width, height, top, left of the .lightblue element
          .lightblue {
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            width: 12rem;
            height: 12rem;
            background-color: $color-light-blue;
            z-index: 0;
          }
        }
      }
    }

    .step2,
    .step3 {
      &.hidden {
        max-height: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
        opacity: 0;
        border: 0;
      }

      max-height: 1000rem;
      transition: all 0.4s ease-in-out;

      // border-top: 4px solid $color-light-grey-3;
      padding-top: 5rem;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: $screenSize-smartphone) {
        padding-top: unset;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;

        & > .half {
          flex-basis: 100%;
          margin-top: 5rem;
        }
      }

      & > .half {
        box-sizing: border-box;
        flex-basis: 100%;
      }

      .half:nth-of-type(1) {
        padding-left: 5rem;
        padding-right: 3.5rem;

        @media only screen and (max-width: $screenSize-smartphone) {
          padding-left: $smallSectionWhitespace;
          padding-right: $smallSectionWhitespace;
        }
      }

      .half:nth-of-type(2) {
        padding-right: 5rem;
        padding-left: 3.5rem;

        @media only screen and (max-width: $screenSize-smartphone) {
          padding-left: $smallSectionWhitespace;
          padding-right: $smallSectionWhitespace;
        }
      }

      & > .full {
        width: 100%;
        box-sizing: border-box;
        padding-left: 5rem;
        padding-right: 5rem;

        @media only screen and (max-width: $screenSize-smartphone) {
          padding-left: $smallSectionWhitespace;
          padding-right: $smallSectionWhitespace;
        }
      }
    }

    .step3 {
      & > .half > ul {
        list-style: none;
        padding: unset;
        margin: unset;

        & > li {
          border-bottom: 2px solid $color-light-grey-2;
          font-size: 1.6rem;
          font-family: "Halcom W00 Medium", "League Spartan", sans-serif !important;
          font-weight: 500;
          padding: 16px 0;
          display: flex;
          justify-content: space-between;

          & > span:nth-child(2) {
            font-weight: 600;
          }
        }
      }

      h5 {
        text-align: center;
        margin: 5rem 0;
      }

      &.active .graphic .graphic__years > ul > li {
        & > .payment {
          &.payment--yelo {
            animation: growFromHeight0 0.75s ease-in forwards;
            animation-delay: 0.6s;
          }

          &.payment--traditional {
            animation: growFromHeight0 1.25s ease-in forwards;
            animation-delay: 0.8s;
          }
        }
      }

      .graphic {
        position: relative;
        width: 100%;
        height: 24rem;
        margin-bottom: 6rem;

        .graphic__sum,
        .graphic__years {
          position: absolute;
          width: 100%;
        }

        .graphic__sum {
          height: 122%;
          bottom: 0;

          & > ul {
            height: 100%;
            list-style: none;
            padding: unset;
            margin: unset;
            display: flex;
            flex-direction: column-reverse;
            justify-content: stretch;
            align-items: stretch;

            & > li {
              flex-grow: 1;
              margin-left: 6.5rem;
              border-bottom: 0.2rem solid $color-light-grey-2;
              display: flex;
              align-items: flex-end;
              font-size: $fs-small;
              line-height: 1;
              color: $color-grey-2;
              text-shadow: $textShadow;

              @media only screen and (max-width: $screenSize-smartphone) {
                margin-left: 3.5rem;
              }

              & > span {
                transform: translate(-6.5rem, 50%);

                @media only screen and (max-width: $screenSize-smartphone) {
                  transform: translate(-3.5rem, 50%);
                }
              }
            }
          }
        }

        .graphic__years {
          height: inherit;

          & > ul {
            list-style: none;
            padding: 0 7rem 0 13.5rem;
            margin: unset;
            height: inherit;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: stretch;

            @media only screen and (max-width: $screenSize-smartphone) {
              padding-left: 6rem;
              padding-right: 3rem;
            }

            & > li {
              flex-grow: 1;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-end;

              @media only screen and (max-width: $screenSize-smartphone) {
                &:not(:last-of-type) {
                  margin-right: 0.75rem;
                }
              }

              .payment {
                visibility: hidden;
                width: 2rem;
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
                display: block;

                @media only screen and (max-width: $screenSize-smartphone) {
                  width: 0.8rem;
                }

                &:first-child {
                  margin-right: $fs-small;

                  @media only screen and (max-width: $screenSize-smartphone) {
                    margin-right: 0.5rem;
                  }
                }

                &--yelo {
                  background-color: $color-yellow;
                }

                &--traditional {
                  background-color: $color-light-blue;
                }
              }

              &::after {
                position: absolute;
                bottom: -3rem;
                text-align: center;
                font-size: $fs-small;
                color: $color-grey-2;
              }

              // TODO - mobile

              &:nth-of-type(1)::after {
                content: "Year 1";

                @media only screen and (max-width: $screenSize-smartphone) {
                  content: "1";
                }
              }

              &:nth-of-type(2)::after {
                content: "Year 2";

                @media only screen and (max-width: $screenSize-smartphone) {
                  content: "2";
                }
              }

              &:nth-of-type(3)::after {
                content: "Year 3";

                @media only screen and (max-width: $screenSize-smartphone) {
                  content: "3";
                }
              }

              &:nth-of-type(4)::after {
                content: "Year 4";

                @media only screen and (max-width: $screenSize-smartphone) {
                  content: "4";
                }
              }

              &:nth-of-type(5)::after {
                content: "Year 5";

                @media only screen and (max-width: $screenSize-smartphone) {
                  content: "5";
                }
              }

              &:nth-of-type(6)::after {
                content: "Year 6";

                @media only screen and (max-width: $screenSize-smartphone) {
                  content: "6";
                }
              }

              &:nth-of-type(7)::after {
                content: "Year 7";

                @media only screen and (max-width: $screenSize-smartphone) {
                  content: "7";
                }
              }

              &:nth-of-type(8)::after {
                content: "Year 8";

                @media only screen and (max-width: $screenSize-smartphone) {
                  content: "8";
                }
              }

              &:nth-of-type(9)::after {
                content: "Year 9";

                @media only screen and (max-width: $screenSize-smartphone) {
                  content: "9";
                }
              }

              &:nth-of-type(10)::after {
                content: "Year 10";

                @media only screen and (max-width: $screenSize-smartphone) {
                  content: "10";
                }
              }
            }
          }
        }
      }
    }
  }
}
