@import "../../../styles/variables.scss";

.App .input-container {
  position: relative;


  // &.currency > input { 
  //     padding-left: 4rem !important;
  // }


  // & .start-adornment {
  //   position: absolute;
  //   overflow: hidden;
  //   display: block;
  //   position: absolute;
  //   top: 4.2rem;
  //   left: 2.4rem;
  //   color:  $color-black;
  // }

  .error > input {
    border-color: $color-orange !important;
    color: $color-orange;
  }

  &.checkbox {
    display: flex;

    & > input {
      width: 20px;
      height: 20px;
    }

    & > label {
      padding-left: 1rem;
    }
  }

  &.required {
    & > label::after {
      content: "*";
      font-size: inherit;
      font-weight: inherit;
      color: $color-light-blue;
      margin-left: 0.3rem;
      display: inline-block;
    }
  }

  &.disabled {
    & > label {
      color: $color-grey-4;
    }
  }

  &.radio {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    width: fit-content !important;
    margin: 0.5rem 0.5rem 0.5rem 0;
    padding: 0 1.5rem;

    border: 2px solid $color-grey;
    border-radius: 20rem;

    &.checked {
      border: 2px solid $color-light-blue;
      background-color: $color-light-grey-2; //TODO: for active button
    }

    & input {
      height: 2rem;
      width: 2rem;
    }

    & label {
      margin-bottom: 0;
      padding-left: 1.5rem;
    }
  }

  &.password {
    .show-password-toggle {
      background-image: url("../../../assets/icons/show-password.svg");
      background-size: 100% auto;
      background-position: center center;
      width: 1.9rem;
      height: 1.5rem;
      text-indent: 999px;
      overflow: hidden;
      display: block;
      position: absolute;
      top: 4.7rem;
      right: 2.4rem;
      cursor: pointer;
    }
  }

  label,
  input,
  select {
    text-align: left;
    font-size: 1.6rem;
    font-weight: 500;
    display: block;
    transition: all 0.1s;

    &:focus-visible {
      outline: unset;
      border: $color-light-blue solid 1px;
      box-shadow: 0 0 0 1px $color-grey-5;
    }

    &::placeholder {
      font-weight: 400;
      color: $color-grey-4;
    }
  }

  label {
    color: $color-blue;
    margin-bottom: 1.2rem;
    padding-left: 2.5rem;
  }

  input,
  select {
    font-size: 1.6rem;
    color: $color-black;
    width: 100%;
    height: 4.5rem;
    padding-left: 2.4rem;
    box-sizing: border-box;
    border-radius: 2.75rem;
    border: 1px solid $color-grey;
    background-color: $color-white;

    &:disabled {
      color: $color-grey-4;
      background-color: $color-light-grey;
    }
  }

  select {
    appearance: none;
    background-image: url("../../../assets/images/select_chevron.png");
    background-repeat: no-repeat;
    background-position: right 3.5rem center;
    background-size: 1.2rem;

    & > option {
      background-color: $color-white;
    }
  }

  .label-above {
    margin-bottom: 8px !important;
  }
}

.error-hint {
  margin-top: 1rem;
  margin-left: 2rem;
  font-size: 1.4rem;
  color: $color-orange;
}

.file-input-container {
  color: $color-light-blue;
  text-decoration: underline;
}
