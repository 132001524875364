@import "../../../styles/variables.scss";

// Blog Header
.section__blog-header {
  position: relative;
  background-color: $color-light-grey;
  padding: 134px 2.5rem 144px;
  text-align: center;
  overflow: hidden;
  
  h1 {
    color: $color-blue;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: $default-line-height;
    text-shadow: 0px 4px 15px rgba(11, 19, 41, 0.05);
    @media only screen and (max-width: $screenSize-smartphone) {
      font-size: 3rem;
    }
  }
  p {
    max-width: 550px;
    margin: 0 auto; 
  }
  .circle1 {
    border: 2px solid $color-light-blue;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    position: absolute;
    top: 57px;
    left: 118px;
  }
  .circle2 {
    background-color: $color-blue;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: absolute;
    bottom: 49px;
    left: 157px;
  }
  .circle3 {
    position: relative;
    background-color: $color-yellow;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 105px;
    left: 409px;
    transform: rotate(-110deg);
    &:before {
      content: '';
      background-color: $color-light-grey;
      width: 16px;
      height: 8px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .circle4 {
    background-color: $color-light-blue;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    position: absolute;
    bottom: -7px;
    left: 825px;
  }
  .circle5 {
    background-color: $color-yellow;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 45px;
    left: 1047px;
  }
  .circle6 {
    border: 2px solid $color-yellow;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    position: absolute;
    top: 78px;
    left: 1538px;
  }
  .circle7 {
    background-color: $color-light-blue;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: absolute;
    bottom: 83px;
    left: 1655px;
  }
  .circle8 {
    background-color: $color-blue;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 99px;
    right: -12px;
  }
}