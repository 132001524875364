@import "../../styles/variables.scss";

body.funding-application {
  background-color: $color-light-grey;


  .stepper-wrapper {
    background-color: #f8f9ff;
    padding: 24px 0px;

    // & > .MuiStepper-root {
    //     max-width: 1200px;
    // }
  }

  .MuiStepLabel-label {
    font-size: clamp(1.25rem, 3vw, 2rem) !important;
    font-family: $font-family; // TODO: change to HALCOM

    &.Mui-disabled {
      color: #657df1 !important;
    }
  }

  .MuiStepConnector-root {
    top: 17px !important;
  }

  .MuiStepIcon-root {
    font-size: 3rem !important;
    border-radius: 20px;

    &:not(.Mui-completed) {
      color: transparent !important;
      border: 2px solid #657df1;
    }

    &.Mui-completed {
      color: #203864 !important;
      border: 2px solid #203864;
    }

    &.Mui-active {
      border-color: #203864 !important;

      & > .MuiStepIcon-text {
        fill: #203864 !important;
      }
    }

    & > .MuiStepIcon-text {
      font-size: 1.2rem;
      font-family: $font-family !important;
      fill: #657df1 !important;
    }
  }

  .Mui-disabled {
    & > .MuiStepConnector-line {
      border-color: #657df1 !important;
    }
  }

  .Mui-active {
    & > .MuiStepConnector-line {
      border-color: #203864 !important;
    }
  }

  .step-wrapper {
    padding: 4rem;

    @media only screen and (max-width: $screenSize-smartphone) {
      padding: 2rem 0 8rem;
    }
  }

  .form-container {
    max-width: 48rem;
    margin-top: 40px;

    @media only screen and (max-width: $screenSize-smartphone) {
      max-width: 100%;
    }
  }

  main {
    position: relative;
    padding-top: 4rem;
    // min-height: calc(100vh - 14.9rem);
    min-height: calc(100vh - 21.6rem);

    & > .innerWrapper {
      position: relative;
      z-index: 1;

      padding-top: 8rem;

      @media only screen and (max-width: $screenSize-smartphone) {
        padding-top: 6rem;
        margin-left: $smallSectionWhitespace;
        margin-right: $smallSectionWhitespace;
      }
    }

    

  }

}

body.funding-application.disbursement {
  & > main {
    // success page
    padding-top: 9rem;
    min-height: calc(100vh - 26.6rem);

    & > .innerWrapper {
      @media only screen and (max-width: $screenSize-smartphone) {
        padding-left: none;
        padding-right: none;
      }
    }
  }
}

body.funding-application.p-test {
  & main > .innerWrapper {
    @media only screen and (max-width: $screenSize-smartphone) {
      margin-left: unset;
      margin-right: unset;
    }
  } 
}

body.funding-application.register {
  & main {
    padding-top: none;

    & > .innerWrapper {
      padding-top: 4rem;
      // background-color: pink;
    }
  }
}