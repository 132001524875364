@import "../../../styles/variables.scss";

.input-container.dropdown {
  // .error > select {
  //   border-color: red !important;
  //   color: red;
  // }
  
  // .checkbox {
  //   display: flex;
  
  //   & > input {
  //     width: 20px;
  //     height: 20px;
  //   }
  
  //   & > label {
  //     padding-left: 1rem;
  //   }
  // }
  
  // & label,
  // & input,
  // & select {
  //   text-align: left;
  //   font-size: 1.6rem;
  //   font-weight: 500;
  //   display: block;
  //   transition: all 0.1s;
  
  //   &:focus-visible {
  //     outline: $color-light-blue solid 2px;
  //   }
  // }
  
  // & label {
  //   color: $color-blue;
  //   margin-bottom: 1.2rem;
  //   padding-left: 2.5rem;
  // }
  
  // & input,
  // & select {
  //   font-size: 1.6rem;
  //   color: $color-black;
  //   width: 100%;
  //   height: 4.5rem;
  //   padding-left: 2.4rem;
  //   box-sizing: border-box;
  //   border-radius: 2.75rem;
  //   border: 1px solid $color-grey;
  //   background: unset;
  
  //   &:disabled {
  //     color: $color-grey-4;
  //     background-color: $color-light-grey;
  //   }
  // }
  
  // select {
  //   appearance: none;
  //   background-image: url("../../../assets/images/select_chevron.png");
  //   background-repeat: no-repeat;
  //   background-position: right 3.5rem center;
  //   background-size: 1.2rem;
  
  //   & > option {
  //     background-color: $color-white;
  //   }
  // }
  
  // .label-above {
  //   margin-bottom: 8px !important;
  // }


  















  & label,
  & input {
    text-align: left;
    font-size: $fs-normal;
    font-weight: 500;
    display: block;
    transition: all 0.1s;
    z-index: 1;
  
    &:focus-visible {
      border: $color-light-blue solid 1px;
      outline: none;
      // border-bottom: none;
    }
  }
  
  & label {
    color: $color-blue;
    margin-bottom: 1.2rem;
    padding-left: 2.5rem;
  }
  
  & input {
    font-size: $fs-normal;
    color: $color-black;
    width: 100%;
    height: 4.5rem;
    padding-left: 2.4rem;
    box-sizing: border-box;
    border-radius: 2.75rem;
    border: 1px solid $color-grey;
    background: unset;

    transition: none;
  
    &:disabled {
      color: $color-grey-4;
      background-color: $color-light-grey;
    }

    &.show-suggestions,
    &.show-suggestions:focus-visible {
      border-bottom-color: $color-grey;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-width: 1px;
    }
  }
  
  .label-above {
    margin-bottom: 8px !important;
  }


  .dropdown-suggestions {
    margin-left: -0.1rem;
    position: absolute;
    max-height: 20rem;
    overflow: hidden;
    outline: none !important;
    border: $color-light-grey-2 solid 1px;
    border-top: none;
    // box-shadow: 0 0 0 1px $color-light-grey-2;
    border-radius: 0 0 2.75rem 2.75rem;
    z-index: 1;
    max-width: 100%;

    &:focus-visible {
      outline: none;
    }
  
    & > .inner-wrapper {
      background-color: white;
      // border: 1px solid #ced5ec;   
      // border-top: none;
      border: $color-light-blue solid 1px;
      // border-top: $color-light-grey-2 solid 1px;
      border-top: none;
      border-radius: inherit;
      box-sizing: border-box;
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
      box-sizing: border-box;
      font-family: sans-serif;
      width: 48rem;
      max-width: inherit;
      padding: 0;
      max-height: inherit;
      overflow-y: auto;
      z-index: 20;

      // &::before {
      //   content: "";
      //   display: block;
      //   width: 100%;
      //   height: 1px;
      //   background-color: $color-light-grey-2;
      // }
    
      & > .option {
        margin: 2.4rem;
        cursor: pointer;

        &:hover {
          color: $color-light-blue;
        }
      }
    }
  }
}