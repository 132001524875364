@import "../../../styles/variables.scss";

.popin-form-container {
  background: url('../../../assets/images/form_bg.svg') no-repeat;
  background-size: cover;
  width: 427px;
  height: 437px;
  padding: 24px;
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 99;
  @media screen and (max-width: $screenSize-iphone13ProMax) {
    right: auto;
    transform: translateX(-50%) scale(0.8);
    left: 50%;
    bottom: 20px;
  }
  @media screen and (min-width: $screenSize-iphone13ProMax) and (max-width: $screenSize-smartphone) {
    right: auto;
    transform: translateX(0) scale(0.8);
    left: auto;
    right: 20px;
    bottom: 20px;
  }
  
  .text-content {
    max-width: 365px;
    margin-left: 24px;
    @media screen and (max-width: $screenSize-iphone13ProMax) {
      max-width: 275px;
      margin-left: 36px;
      padding-top: 25px;
    }
  }

  h2 {
    color: $color-white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 15px;
    margin-bottom: 5px;
    @media screen and (max-width: $screenSize-iphone13ProMax) {
      margin-top: 10px;
    }
  }
  p {
    color: $color-white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
  }
  form {
    gap: 0.75rem;
    max-width: 365px;
    margin-left: 24px;
    @media screen and (max-width: $screenSize-iphone13ProMax) {
      max-width: 338px;
      margin-left: 38px;
    }
  }
  label {
    display: none !important;
  }
  input {
    background-color: transparent !important;
    color: $color-grey !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .input-error input, .input-error textarea {
    border: 1px solid red;
    color: red !important;
  }
  textarea {
    background-color: transparent;
    border: 1px solid $color-grey;
    border-radius: 20px;
    color: $color-grey;
    width: 85%;
    height: 117px;
    resize: none;
    padding: 13px 25px;
    @media screen and (max-width: $screenSize-iphone13ProMax) {
      height: 80px;
    }
    &:focus {
      outline: none;
    }
  }
  ::placeholder {
    color: $color-grey !important;
  }
  button.button {
    height: 45px !important;
    z-index: 1;
  }
  .close-btn {
    color: $color-white;
    cursor: pointer;
    font-size: 3rem;
    position: absolute;
    top: 15px;
    right: 30px;
    &:hover {
      &:before, &:after {
        display: none;
      }
    }
    @media screen and (max-width: $screenSize-iphone13ProMax) {
      right: 30px;
    }
  }
}

.step-2-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  max-width: 365px;
  margin-left: 24px;
  @media screen and (max-width: $screenSize-iphone13ProMax) {
    max-width: 335px;
    margin-left: 40px;
  }
}

.applyNowBtn {
  @media only screen and (min-width: $screenSize-iphone13ProMax) {
    position: fixed;
    bottom: 4rem;
    right: -8.8rem;
    box-sizing: border-box;
    padding-bottom: 3.4rem;
    width: 15.3rem;
    height: 15.3rem;
    color: $color-blue;
    font-size: 1.5rem;
    letter-spacing: 0.04em;
    text-decoration: none;
    font-weight: 400;
    border-radius: 50%;
    background-color: $color-yellow;
    border: unset;
    box-shadow: $box-shadow;
    cursor: pointer;
    z-index: 100;
    transform: rotate(90deg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(110%) rotate(90deg);
      margin-right: 0.5rem;
    }
  }

  @media only screen and (max-width: $screenSize-smartphone) {
    background: url('../../../assets/icons/chat_icon.svg') no-repeat;
    position: fixed;
    right: 10px;
    bottom: 20px;
    height: 70px;
    width: 70px;
    span {
      opacity: 0;
    }
  }
}