@keyframes rotate360 {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateMinus30Plus100 {
  from {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(100deg);
  }
}

@keyframes rotateAndGrow360 {
  from {
    transform: rotate(0deg) scale(120%);
  }
  to {
    transform: rotate(360deg) scale(120%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeInSlideInFromBottom {
  from {
    transform: translateY(20%);
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeInScaleIn {
  from {
    transform: scale(0);
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: scale(100%);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeInOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes grow120 {
  from {
    transform: scale(100%);
  }
  to {
    transform: scale(120%);
  }
}

@keyframes grow150 {
  from {
    transform: scale(100%);
  }
  to {
    transform: scale(150%);
  }
}

@keyframes grow200 {
  from {
    transform: scale(100%);
  }
  to {
    transform: scale(200%);
  }
}

@keyframes growFromHeight0 {
  from {
    height: 0;
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}

@keyframes moveAround {
  to {
    transform: translate(2%, -5%);
  }
}

@keyframes fundingsCirclesDesktop {
  from {
    top: 41rem;
    left: 45%;
    transform: scale(0);
    opacity: 0;
    visibility: hidden;
    z-index: 0;
  }
  to {
    transform: scale(100%);
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
}
