@import "../../../styles/variables.scss";

.card__chip {
  display: inline-block;
  background-color: $color-white;
  border-radius: 100px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  padding: 3px 12px 5px;
  &.card__chip--red {
    background-color: $color-light-orange;
    color: $color-orange;
  }
  &.card__chip--orange {
    background-color: $color-lighter-orange;
    color: $color-dark-orange;
  }
  &.card__chip--green {
    background-color: $color-light-green;
    color: $color-green;
  }
  &.card__chip--blue {
    background-color: $color-grey-7;
    color:  $color-black;
  }
}