@import "../../../styles/variables.scss";

.App .section--aboutUs {
  & > .section__innerWrapper {
    background-color: $color-light-grey;
    padding-block: 14rem;

    .anchor:before {
      height: 12.5rem;
      margin: -12.5rem 0 0;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      padding-block: 8rem;
    }
  }

  article h2.animateIn,
  article h3.animateIn,
  .slick-slider {
    visibility: hidden;
  }

  &.onScreen .section__innerWrapper {
    article h2.animateIn {
      animation: $animationDuration-short ease-in-out $animateIn-Delay-1
        forwards fadeInSlideInFromBottom;
    }
    article h3.animateIn {
      animation: $animationDuration-short ease-in-out $animateIn-Delay-2
        forwards fadeInSlideInFromBottom;
    }
    .slick-slider {
      animation: $animationDuration-medium ease-in-out $animateIn-Delay-3
        forwards fadeIn;
    }
  }

  .slick-slider {
    margin-top: 5rem;

    @media only screen and (max-width: $screenSize-smartphone) {
      margin-top: 8rem;
    }

    .slick-next:hover,
    .slick-prev:hover {
      color: #f9df1c;
    }

    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 150px;

      .slide-wrapper {
        padding: 2rem 3rem;

        @media only screen and (max-width: $screenSize-smartphone) {
          padding: 0;
        }

        img {
          cursor: pointer;
          margin: 0 auto;
          min-width: 100px;
          max-width: 150px;
          max-height: 100px;
        }
      }
    }
  }
  .slick-dots {
    bottom: -4rem;

    li {
      margin: 0 0.5rem;

      &.slick-active button {
        background-color: #203864;
        width: 18px;
        height: 18px;
      }

      button {
        width: 18px;
        height: 18px;
        background-color: #A6B1C5;
        border-radius: 50%;
        transition: background-color 0.3s ease;
      }
    }
  }
}
