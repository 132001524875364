@import "../../../styles/variables.scss";

.modal {
  font-family: $font-family;

  & > .documents-modal {
    display: flex;
    width: 100%;
    max-width: 60vw;
    padding: 2rem;
    height: 70vh;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 20px;

    font-size: $fs-normal;
    color: $color-black;

    @media only screen and (max-width: $screenSize-smartphone) {
      margin-left: $smallSectionWhitespace;
      margin-right: $smallSectionWhitespace;
      max-width: calc(100% - (2 * $smallSectionWhitespace));
      box-sizing: border-box;
      flex-direction: column;
      height: 80dvh;
      position: relative;
      padding-top: 10rem;
    }

    & > .list {
      width: 25%;
      border-right: 1px solid $color-grey;
      margin-right: 3rem;

      @media only screen and (max-width: $screenSize-smartphone) {
        max-width: 100%;
        height: 7rem;
        min-height: 7rem;
        overflow-x: scroll;
        border-right: unset;
        border-bottom: 1px solid $color-grey;
        margin-right: unset;
        left: 0;
        margin-bottom: 3rem;
        position: absolute;
        top: 0;
        width: 100%;
        padding-left: inherit;
        padding-right: inherit;
        box-sizing: border-box;
      }

      & > .inner-wrapper {
        height: 100%;

        @media only screen and (max-width: $screenSize-smartphone) {
          display: flex;
          align-items: stretch;
        }

        & > .list-element {
          margin: 1rem 0;
          padding: 0.5rem 1rem 0.25rem;
          line-height: 1.3;
          text-wrap: balance;
          text-align: right;
          border-right: 1px solid transparent;
          border-radius: 1rem 0 0 1rem;
          transition: 200ms ease-in-out;
          cursor: pointer;

          @media only screen and (max-width: $screenSize-smartphone) {
            display: inline-block;
            white-space: nowrap;
            margin: 0;
            padding: 0 1rem;
            border-radius: initial;
            display: flex;
            align-items: center;
          }

          &:hover {
            background: linear-gradient(235deg, #f5f5ff, transparent 50%);
          }

          &.selected {
            color: $color-light-blue;
            border-right: 1px solid $color-light-blue;
            transform: translateX(1px);
            background: linear-gradient(235deg, #f5f5ff, transparent 50%);

            @media only screen and (max-width: $screenSize-smartphone) {
              border-right: unset;
              border-bottom: 2px solid $color-light-blue;
              transform: initial;
              background: initial;
            }
          }
        }
      }
    }

    & > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70%;

      @media only screen and (max-width: $screenSize-smartphone) {
        width: 100%;
        justify-content: flex-start;

        height: calc(75vh - 15rem);
      }

      & > h2 {
        margin: 1.5rem 0;
        font-weight: 500;
        text-align: center;
        text-wrap: balance;
        line-height: 1.3;

        @media only screen and (max-width: $screenSize-smartphone) {
          font-size: 2rem;
        }
      }

      & > .body {
        margin-bottom: 1.5rem;
        height: calc(100% - 14rem);
        overflow-y: auto;
        width: 100%;
        padding-inline: 1rem;
      }

      & > button {
        z-index: 20;
      }

      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      .fade-out {
        animation: fadeOut 1s forwards;
      }
    }
  }
}
