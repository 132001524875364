@import "../../styles/variables.scss";

.article__breadcrumb {
  padding-top: 20px;
}
.article__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $screenSize-smartphone) {
    display: block;
    margin-top: 20px;
  }
  .article__image {
    float: right;
    width: 40%;
    margin-top: 40px;
    margin-left: 20px;
    margin-bottom: 10px;
    border-radius: 20px;
    @media screen and (max-width: $screenSize-smartphone) {
      float: none;
      margin-left: 0;
      width: 100%;
    }
  }
}
.article__header--content {
  width: 70%;
  padding-bottom: 50px;
  padding-top: 50px;
  @media screen and (max-width: $screenSize-smartphone) {
    width: 100%;
    padding: 20px 0 0;
  }
  h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px;
  }
}
.article__content {
  color: $color-blue;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 40px;

  video, img {
    height: auto !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 20px 0 10px !important;
  }
  iframe {
    width: 100% !important;
    max-width: 100% !important;
    margin: 20px 0 10px !important;
  }
}
.article_summary {
  margin: 10px 0;
}
.article__date-time {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  opacity: 0.6;
}
.article__image--holder {
  position: relative;
}
.article__image--element {
  border-radius: 50%;
  background: linear-gradient(to right, rgba(249, 223, 28, 0.9) 50%, rgba(32, 56, 100, 0.9) 50%);
  position: absolute;
  z-index: 2;
  &.big {
    width: 150px;
    height: 150px;
    top: -115px;
    left: -74px;
    @media screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }
  &.small {
    width: 90px;
    height: 90px;
    right: -60px;
    bottom: -39px;
    transform: rotate(80deg);
    @media screen and (max-width: $screenSize-smartphone) {
      bottom: -45px;
      right: 40px;
      transform: rotate(90deg);
    }
  }
}