@import "../../../styles/variables.scss";

.App > main.login {
  position: relative;
  min-height: calc(100vh - 7.5rem);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  // padding: 7.5rem 0rem 0;

  @media only screen and (max-width: $screenSize-smartphone) {
    min-height: unset;
  }

  & .content {
    z-index: 10;
    position: relative;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    margin-bottom: 10rem;

    @media only screen and (max-width: $screenSize-tablet) {
      padding-left: $smallSectionWhitespace;
      padding-right: $smallSectionWhitespace;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      justify-content: flex-start;
      margin-top: 5rem;
      margin-bottom: 8rem;
    }

    & h1 {
      color: $color-white;
      font-size: 4rem;
      font-weight: 500;
      text-shadow: $textShadow;
      margin-bottom: 7.5rem;

      @media only screen and (max-width: $screenSize-smartphone) {
        font-size: 3rem;
        margin-bottom: 4rem;
      }
    }
  }

  .box {
    width: fit-content;
    max-width: 56rem;
    padding: 4rem;

    @media only screen and (max-width: $screenSize-smartphone) {
      max-width: 100%;
      padding: $smallSectionWhitespace;
    }

    .form-container {
      margin-top: 0;
      width: 480px;
      max-width: 100%;
    }
  }
}

.new-code-container {
  margin: 1rem;
  padding: 2rem;
  border-radius: 12px;
  background-color: $color-light-grey;
}

.react-code-input {
  padding: 2rem 0px;
  display: flex !important;

  & > input[type="number"]::-webkit-inner-spin-button,
  & > input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.link {
  font-size: 13px;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: $color-light-blue;
  }
}
