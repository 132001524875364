@import "../../../styles/variables.scss";

// $offsetTop-desktop: 50rem;
$offsetTop-desktop: 0rem;

.homeDecorations {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 100%;
  max-width: $containerMaxWidth;

  & > * {
    position: absolute;
    display: block;
    border-radius: 50%;
  }

  .d1 {
    background-color: $color-yellow;
    width: 2.7rem;
    height: 2.7rem;
    top: calc(130rem + $offsetTop-desktop - 12rem);
    left: 6.77vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 13rem;
    }
  }

  .d2 {
    border: 0.3rem solid $color-light-blue;
    width: 3.2rem;
    height: 3.2rem;
    top: calc(120rem + $offsetTop-desktop -  12rem);
    right: 291vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: 5.6rem;
    }
  }

  .d3 {
    background-color: $color-yellow;
    width: 0.9rem;
    height: 0.9rem;
    top: calc(156rem + $offsetTop-desktop - 12rem);
    left: 60.4vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 116rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      left: 95vw;
    }
  }

  .d4 {
    background-color: $color-yellow;
    width: 2.7rem;
    height: 2.7rem;
    top: calc(180rem + $offsetTop-desktop - 12rem);
    left: 61vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 125rem;
    }

    @media only screen and (max-width: $screenSize-720p) {
      margin-top: 7rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      left: 80vw;
    }
  }

  .d5 {
    border: 0.3rem solid $color-light-blue;
    width: 4.5rem;
    height: 4.5rem;
    top: calc(225rem + $offsetTop-desktop - 12rem);
    left: 2.08vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 4rem;
    }
  }

  .d6 {
    background-color: $color-blue;
    width: 1.7rem;
    height: 1.7rem;
    top: calc(198rem + $offsetTop-desktop - 12rem);
    left: 29.53vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 56.7rem;
      margin-top: -5rem;
    }

    @media only screen and (max-width: $screenSize-720p) {
      margin-top: -7rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }

  .d7 {
    background-color: $color-blue;
    width: 0.9rem;
    height: 0.9rem;
    top: calc(192rem + $offsetTop-desktop - 12rem);
    right: 13.33vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: 25.6rem;
    }
  }

  .d8 {
    background-color: $color-blue;
    width: 0.9rem;
    height: 0.9rem;
    top: calc(268rem + $offsetTop-desktop - 12rem);
    right: 8.25vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: 21.6rem;
    }
  }

  .d9 {
    border: 0.3rem solid $color-light-blue;
    width: 4.5rem;
    height: 4.5rem;
    top: calc(225rem + $offsetTop-desktop - 12rem);
    right: 1.56vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: 3rem;
    }
  }

  .d10 {
    border: 0.3rem solid $color-light-blue;
    width: 4.5rem;
    height: 4.5rem;
    top: calc(361rem + $offsetTop-desktop - 12rem);
    right: 1.56vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: 3rem;
    }
  }

  .d11 {
    background-color: $color-yellow;
    width: 1.3rem;
    height: 1.3rem;
    top: calc(323rem + $offsetTop-desktop - 12rem);
    left: 27.8vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: 53.5rem;
    }

    @media only screen and (max-width: $screenSize-720p) {
      margin-top: 20rem;
    }

    @media only screen and (max-width: $screenSize-tablet) {
      margin-top: 13rem;
    }

    @media only screen and (max-width: $screenSize-iphone13ProMax) {
      margin-top: -6rem;
    }
  }

  .d12 {
    background-color: $color-light-blue;
    width: 2.4rem;
    height: 2.4rem;
    top: calc(304rem + $offsetTop-desktop + 12rem);
    left: 90.0vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: unset;
      right: 90.5rem;
    }

    @media only screen and (max-width: $screenSize-720p) {
      margin-left: 2rem;
    }

    @media only screen and (max-width: $screenSize-tablet) {
      margin-left: -1rem;
    }
  }

  .d13 {
    border: 0.3rem solid $color-yellow;
    width: 2.1rem;
    height: 2.1rem;
    top: calc(306rem + $offsetTop-desktop + 12rem);
    right: 1.82vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: 3.5rem;
    }
  }

  .d14 {
    background-color: $color-light-blue;
    width: 2.2rem;
    height: 2.2rem;
    top: calc(351rem + $offsetTop-desktop + 12rem);
    right: 2.23vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: 4.3rem;
    }
  }

  .d15 {
    background-color: $color-light-blue;
    width: 1.3rem;
    height: 1.3rem;
    top: calc(416rem + $offsetTop-desktop + 12rem);
    left: 34.16vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 65.5rem;
    }

    @media only screen and (max-width: $screenSize-iphone13ProMax) {
      margin-top: 10rem;
    }

    @media only screen and (max-width: $screenSize-iphone13) {
      margin-top: 4rem;
    }

    @media only screen and (max-width: $screenSize-iphone13mini) {
      margin-top: 0rem;
    }
  }

  .d16 {
    background-color: $color-yellow;
    width: 1.3rem;
    height: 1.3rem;
    top: calc(420rem + $offsetTop-desktop + 12rem);
    right: 34.16vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: 35.5rem;
    }

    @media only screen and (max-width: $screenSize-tablet) {
      margin-top: -30rem;
      margin-right: -10rem;
    }

    @media only screen and (max-width: $screenSize-iphone13ProMax) {
      margin-top: 8rem;
    }

    @media only screen and (max-width: $screenSize-iphone13mini) {
      margin-top: 0rem;
    }
  }

  // .d17 {
  //   background-color: $color-yellow;
  //   width: 1.7rem;
  //   height: 1.7rem;
  //   top: calc(480rem + $offsetTop-desktop + 12rem);
  //   left: 9.37vw;
  //   @media only screen and (min-width: $screenSize-1080p-plus) {
  //     right: 18rem;
  //   }
  // }

  .d17 {
    background-color: $color-light-blue;
    width: 1.3rem;
    height: 1.3rem;
    top: calc(480rem + $offsetTop-desktop + 12rem);
    left: 6.5vw; //12.5vw;
    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 24rem;
    }
  }

  .d18 {
    border: 0.3rem solid $color-light-blue;
    width: 3rem;
    height: 3rem;
    top: calc(518rem + $offsetTop-desktop + 12rem);
    right: 3.65vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: 7rem;
    }
  }

  .d19 {
    border: 0.3rem solid $color-yellow;
    width: 3rem;
    height: 3rem;
    top: calc(580rem + $offsetTop-desktop + 12rem);
    left: 8.6vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 16.5rem;
    }
  }

  .d20 {
    border: 0.3rem solid $color-light-blue;
    width: 3rem;
    height: 3rem;
    top: calc(587rem + $offsetTop-desktop + 12rem);
    left: 50vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 97.5rem;
    }
  }

  .d21 {
    background-color: $color-light-blue;
    width: 1.3rem;
    height: 1.3rem;
    top: calc(641rem + $offsetTop-desktop + 12rem);
    left: 4.45vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 85.6rem;
    }
  }

  .d22 {
    background-color: $color-light-blue;
    width: 1.9rem;
    height: 1.9rem;
    top: calc(575rem + $offsetTop-desktop + 12rem);
    right: 4.45vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 85.6rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      margin-top: -5rem;
    }

    @media only screen and (max-width: $screenSize-iphone13mini) {
      margin-top: -5rem;
    }
  }

  .d23 {
    background-color: $color-yellow;
    width: 1.9rem;
    height: 1.9rem;
    top: calc(676rem + $offsetTop-desktop + 12rem);
    left: 3vw;

    // @media only screen and (min-width: $screenSize-1080p-plus) {
    //   left: 21rem;
    // }
    @media only screen and (max-width: $screenSize-1080p) {
      margin-top: 5rem;
    }

    @media only screen and (max-width: $screenSize-720p) {
      margin-top: -15rem;
    }

    @media only screen and (max-width: $screenSize-tablet) {
      margin-top: -22rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }

  .d24 {
    background-color: $color-yellow;
    width: 1.9rem;
    height: 1.9rem;
    top: calc(705rem + $offsetTop-desktop + 12rem);
    right: 12.5vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 24rem;
      margin-top: 30rem;
    }

    @media only screen and (max-width: $screenSize-720p) {
      margin-top: -27rem;
      margin-right: -7rem;
    }

    @media only screen and (max-width: $screenSize-tablet) {
      margin-right: 0rem;
    }
  }

  .d25 {
    border: 0.3rem solid $color-light-blue;
    width: 3rem;
    height: 3rem;
    top: calc(660rem + $offsetTop-desktop + 12rem);
    left: 4.7vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 9rem;
    }
  }

  .d26 {
    background-color: $color-light-blue;
    width: 2.7rem;
    height: 2.7rem;
    top: calc(798rem + $offsetTop-desktop + 12rem);
    left: 40.4vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 73.7rem;
    }

    @media only screen and (max-width: $screenSize-720p) {
      margin-left: 40rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      margin-left: 0;
      top: calc(716rem + $offsetTop-desktop + 12rem);
    }

    @media only screen and (max-width: $screenSize-iphone13ProMax) {
      margin-top: -20rem;
    }
  }

  .d27 {
    background-color: $color-yellow;
    width: 1.9rem;
    height: 1.9rem;
    top: calc(721rem + $offsetTop-desktop + 12rem);
    left: 42vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 80.7rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      top: calc(730rem + $offsetTop-desktop + 12rem);
    }

    @media only screen and (max-width: $screenSize-720p) {
      margin-top: 15rem;
      margin-left: 10rem;
    }

    @media only screen and (max-width: $screenSize-iphone13ProMax) {
      display: none;
    }
  }

  .d28 {
    border: 0.3rem solid $color-light-blue;
    width: 3rem;
    height: 3rem;
    top: calc(799rem + $offsetTop-desktop + 12rem);
    right: 5.52vw;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: 10.6rem;
    }
  }
}
