@import "../../../styles/variables.scss";

.App .section--whoWeAre {
  & > .section__innerWrapper {
    margin-top: 14.5rem;
    margin-bottom: 21.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $color-blue;

    @media only screen and (max-width: $screenSize-smartphone) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      margin-top: 8.5rem;
      margin-bottom: 15rem; //8rem;
    }

    @media only screen and (max-width: $screenSize-iphone13ProMax) {
      margin-bottom: 8rem;
    }
  }

  // onScreen animations
  article h2.animateIn,
  article.animateIn,
  .visuals .visual {
    visibility: hidden;
  }

  &.onScreen .section__innerWrapper article h2.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-1 forwards fadeInSlideInFromBottom;
  }

  &.onScreen .section__innerWrapper article article.animateIn:nth-of-type(1) {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-2 forwards fadeInSlideInFromBottom;
  }

  &.onScreen .section__innerWrapper article article.animateIn:nth-of-type(2) {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-3 forwards fadeInSlideInFromBottom;
  }

  &.onScreen .section__innerWrapper .visual--1.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-5 forwards fadeInScaleIn;
  }

  &.onScreen .section__innerWrapper .visual--2.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-4 forwards fadeInScaleIn;
    overflow: hidden;

    // &::after {
    //   animation: $animationDuration-short ease-in-out $animateIn-Delay-5 forwards
    //   fadeInScaleIn;
    // }
 
  }

  h2 {
    font-size: 4rem;
  }

  & article {
    max-width: 55rem;
    flex-shrink: 1;

    h3 {
      font-size: 2.4rem;
      margin-bottom: 2.4rem;
    }

    p {
      font-size: 1.6rem;
    }

    article {
      margin-top: 4rem;
    }
  }

  .visuals {
    position: relative;
    width: 45%;
    max-width: 68.7rem;
    margin-left: 20%;

    @media only screen and (max-width: $screenSize-smartphone) {
      width: 100%;
      height: 141vw;
      margin: 5.4rem 0 0;
    }

    .visual {
      border-radius: 50%;
      overflow: hidden;
    }

    .visual--1 {
      position: absolute;

      // top: 55%;
      // left: -35%;
      top: 70%;
      left: -40%;
      width: 55.3%;
      height: 0%;
      padding-bottom: 55.3%;

      // z-index: 100; // fix for the intersection pseudo element flicker on top bug

      @media only screen and (max-width: $screenSize-smartphone) {
        width: 63.5vw;
        padding-bottom: 63.5vw;
        top: 96.4vw;
        left: unset;
        right: 1vw;
      }
    }

    .visual--2 {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      z-index: 1;

      &__yellowCirc {
        content: "";
        position: absolute;

        // top: 55%;
        // left: -35%;
        top: 70%;
        left: -40%;
        width: 55.3%;
        height: 0%;
        padding-bottom: 55.3%;
        border-radius: 50%;
        background-color: $color-yellow;
        mix-blend-mode: hard-light;
        opacity: 0.9;

        @media only screen and (max-width: $screenSize-smartphone) {
          width: 63.5vw;
          padding-bottom: 63.5vw;
          top: 96.4vw;
          left: unset;
          right: 1vw;
        }
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
