@import "../../../styles/variables.scss";

.siteFooter {
  background-color: $color-blue;
  color: $color-white;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  &.condensed {
    .resources ul {
      list-style: none;
      padding-left: none;

      & > li > a {
        &:link,
        &:visited,
        &:hover,
        &:active {
          text-decoration: none;
        }
      }
    }
  }

  a,
  a:active,
  a:hover {
    color: $color-white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .resources ul {
    & > li > a {
      &:link,
      &:visited,
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  .details,
  .bar {
    width: 100%;
    max-width: $containerMaxWidth;
    box-sizing: border-box;
    padding-left: $defaultSectionWhitespace;
    padding-right: $defaultSectionWhitespace;

    @media only screen and (max-width: $screenSize-tablet) {
      padding-left: $smallSectionWhitespace;
      padding-right: $smallSectionWhitespace;
    }
  }

  .details {
    padding-top: 5.3rem;
    padding-bottom: 5.3rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    font-size: 1.6rem;
    font-weight: 500;

    @media only screen and (max-width: $screenSize-smartphone) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    h3 {
      font-size: inherit;
      font-weight: 600;
      margin-bottom: 2.4rem;

      @media only screen and (max-width: $screenSize-smartphone) {
        margin-bottom: 1.4rem;
      }
    }

    ul {
      list-style: none;
      padding: unset;
      margin: unset;
    }

    & > article {
      order: 2;

      @media only screen and (max-width: $screenSize-smartphone) {
        text-align: center;
      }

      &:not(:last-child) {
        margin-right: 6.5rem;

        @media only screen and (max-width: $screenSize-smartphone) {
          margin-right: unset;
          margin-top: 4rem;
        }
      }
    }

    .contactUs {
      flex-grow: 5;
    }

    .social {
      @media only screen and (max-width: $screenSize-smartphone) {
        order: 1;
      }

      li {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 1.6rem;
          @media only screen and (max-width: $screenSize-smartphone) {
            margin-right: 1.2rem;
          }
        }
      }

      a img {
        width: 4rem;
        @media only screen and (max-width: $screenSize-smartphone) {
          width: 3.5rem;
        }
      }
    }
  }

  .bar {
    width: 100%;
    box-sizing: border-box;

    padding-top: 2.4rem;
    padding-bottom: 2.4rem;

    border-top: 4px solid #485c80;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 1.2rem;
    line-height: 2.1rem;

    @media only screen and (max-width: $screenSize-smartphone) {
      flex-wrap: wrap;

      & ul.social {
        order: 1;
      }

      & > .copyright {
        order: 2;
      }

      & > .terms-and-conditions {
        order: 3;
      }
    }

    & ul.social {
      list-style: none;
      padding: unset;
      margin: unset;

      @media only screen and (max-width: $screenSize-smartphone) {
        width: 100%;
        text-align: center;
        margin-bottom: 2.5rem;
      }

      & > li {
        display: inline-block;
        margin: 0 0.8rem;
      }
    }
  }
}
