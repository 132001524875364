@import "../../styles/variables.scss";

.siteHeader {
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  box-sizing: border-box;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: unset;
  padding-right: unset;
  background-color: #ffffff;

  // box-shadow: $box-shadow; -> new design only uses a border bottom - !remove
  border-bottom: 1px solid $color-light-grey-2;
  color: $color-blue;
  font-weight: 500;

  a {
    text-decoration: none !important;
  }

  .burger {
    align-self: center;
    display: none;
    margin: unset;
    padding: unset;
    border: unset;
    background: unset;
    position: relative;
    width: 2.5rem;
    cursor: pointer;

    @media only screen and (max-width: $screenSize-smartphone) {
      display: block;
      margin-left: 1.6rem;
    }

    & > span,
    &::before,
    &::after {
      display: block;
      width: inherit;
      height: 0.2rem;
      background-color: $color-blue;
      border-radius: 0.2rem;
      margin: 0.4rem 0;
      transition: 0.4s;
    }

    & > span {
      overflow: hidden;
      text-indent: -99rem;
    }

    &::before,
    &::after {
      content: "";
    }

    &.burger.close {
      &::before {
        transform: translate(0, 0.6rem) rotate(-45deg);
      }

      & > span {
        opacity: 0;
      }

      &::after {
        transform: translate(0, -0.6rem) rotate(45deg);
      }
    }
  }

  .siteNavigation {
    width: 100%;
    max-width: $containerMaxWidth;
    margin: 0 auto;
    box-sizing: border-box;
    padding-right: $defaultSectionWhitespace;
    padding-left: $defaultSectionWhitespace;
    font-size: 1.5rem;
    line-height: 1.13;
    color: inherit;
    font-weight: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    & .logo > img {
      height: 100%;
    }

    @media only screen and (max-width: $screenSize-tablet) {
      padding-right: $smallSectionWhitespace;
      padding-left: $smallSectionWhitespace;
    }

    @media only screen and (max-width: $screenSize-tablet) {
      & > * {
        order: 1;
      }

      flex-wrap: wrap;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      & .logo {
        flex-grow: 1;
      }
    }

    ul {
      list-style: none;
      margin: unset;
      padding: unset;
      display: flex;
    }

    .btn {
      min-width: 14.4rem;
      height: 4.5rem;
      line-height: 4.5rem;
      box-sizing: border-box;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      background-color: $color-yellow;
      border-radius: 2.2rem;
      text-align: center;

      @media only screen and (max-width: $screenSize-tablet) {
        min-width: 10rem;
      }

      &--outline {
        border: 1px solid $color-yellow;
        background-color: unset;
      }
    }

    .siteNavigation__main {
      li {
        display: flex;
        gap: 40px;

        & > a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          position: relative;
          color: inherit;
          font-weight: inherit;
          text-decoration: none;

          &,
          &::after {
            transition: all $animationDuration-long ease;
            transition-delay: 0.1s;
          }

          &::after {
            content: "";
            max-width: 0;
            width: 100%;
            height: 3px;
            background-color: currentColor;
            border-radius: 1rem;
            position: absolute;
            bottom: -1.56rem;
          }
        }

        & > a:hover,
        & > a:active,
        & > a.active {
          color: $color-light-blue;

          &::after {
            background-color: $color-light-blue;
            max-width: 100%;
          }
        }
      }

      @media only screen and (max-width: $screenSize-1080p) {
        gap: 2.91vw;
      }
      @media only screen and (min-width: $screenSize-1080p) {
        gap: 2.91vw;
      }

      @media only screen and (max-width: $screenSize-tablet) {
        gap: 1.9vw;
      }

      @media only screen and (max-width: $screenSize-smartphone) {
        width: 100%;
        order: 4;
        margin: unset;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-height: 0;
        overflow: hidden;
        transition: all 0.2s ease-in;

        & > li {
          font-size: 1.6rem;
          margin: 2rem 0;
        }
      }
    }

    .siteNavigation__auth {
      li:not(:last-child) {
        margin-right: 1.6rem;
        letter-spacing: 0.04em;
      }
    }

    li {
      display: inline-block;
    }
  }

  &.home .siteNavigation {
    @media only screen and (max-width: $screenSize-smartphone) {
      &.open {
        padding-bottom: 4rem;
      }

      &.open .siteNavigation__main,
      &.open .siteNavigation__auth {
        max-height: 100vw;
      }

      &.open .siteNavigation__main {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
      }

      // & .siteNavigation__auth .applyNow {
      //   display: none;
      // }

      // &.open .siteNavigation__auth .applyNow {
      //   display: block;
      //   position: absolute;
      //   bottom: 2.5rem;
      //   left: 50%;
      //   transform: translateX(-50%);
      // }

      & .siteNavigation__auth .login {
        display: none;
      }

      &.open .siteNavigation__auth .login {
        display: block;
        position: absolute;
        bottom: 2.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
