@import "../../../styles/variables.scss";

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.indicator) {
    height: calc(100vh - 75px);
    width: 100vw;
  }
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

@keyframes ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.loader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $color-black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader div:nth-child(1) {
  left: 8px;
  animation: ellipsis1 0.6s infinite;
}

.loader div:nth-child(2) {
  left: 8px;
  animation: ellipsis2 0.6s infinite;
}

.loader div:nth-child(3) {
  left: 32px;
  animation: ellipsis2 0.6s infinite;
}

.loader div:nth-child(4) {
  left: 56px;
  animation: ellipsis3 0.6s infinite;
}
