@import "../../../styles/variables.scss";

.App .section--faq {
  & > .section__innerWrapper {
    margin: 7rem auto 14rem;
    width: $centeredInnerMaxWidth;

    @media only screen and (max-width: $screenSize-mac14) {
      width: 100%;
      margin-left: unset;
      margin-right: unset;
      margin-bottom: 8rem;
    }
  }

  article h2.animateIn,
  article h3.animateIn,
  .accordion.animateIn {
    visibility: hidden;
  }

  .accordion {
    background-color: $color-white;
  }

  &.onScreen .section__innerWrapper article h2.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-1 forwards
      fadeInSlideInFromBottom;
  }

  &.onScreen .section__innerWrapper article h3.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-2 forwards
      fadeInSlideInFromBottom;
  }

  &.onScreen .section__innerWrapper article .accordion.animateIn {
    animation: $animationDuration-medium ease-in-out $animateIn-Delay-3 forwards
      fadeInSlideInFromBottom;
  }
}
