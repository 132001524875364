@import "../../../styles/variables.scss";

.accordion {
  margin: unset;
  margin-top: 7rem;
  list-style: none;
  padding: unset;

  @media only screen and (max-width: $screenSize-mac14) {
    margin-top: 6rem;
  }

  .item {
    padding: 0rem 1rem 0;
    border-bottom: 2px solid $color-light-grey-2;
    line-height: $default-line-height;
    color: $color-blue;

    .title {
      font-size: 1.8rem;
      margin: unset;

      div {
        position: relative;

        text-align: left;
        font-weight: 400;
        cursor: pointer;
        display: block;
        width: 100%;
        padding: 3rem 3rem 3rem 0;
        box-sizing: border-box;
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        background: unset;
        border: unset;
        margin: unset;

        &::after,
        &::before {
          content: "";

          position: absolute;
          top: 50%;
          right: 0;

          transform: translateY(-50%);

          display: block;
          width: 1.7rem;
          height: 0.2rem;
          background-color: $color-blue;
        }

        &::after {
          transform: translateY(-50%) rotate(90deg);
          transition: all ease-in-out 0.2s;
        }
      }
    }

    .body {
      font-size: 1.6rem;
      max-height: 0;
      overflow: hidden;
      transition: all ease-in-out 0.5s;
    }

    &.expanded {
      .body {
        max-height: unset;
        padding: 3rem 0px;
      }

      .title div {
        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }
}
