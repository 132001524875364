@import "../../../styles/variables.scss";

.App > main.verification {
  position: relative;
  min-height: calc(100vh -  34.2rem); // vh - header - footer - stepper
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  & .content {
    z-index: 10;
    position: relative;
    flex-grow: 1;

    margin-bottom: 10rem;

    @media only screen and (max-width: $screenSize-tablet) {
      padding-left: $smallSectionWhitespace;
      padding-right: $smallSectionWhitespace;
    }

    & h1 {
      color: $color-white;
      font-size: 4rem;
      font-weight: 500;
      text-shadow: $textShadow;
      margin-bottom: 7.5rem;
    }

    .box {
      width: 56rem;
      max-width: 100%;
      padding: 4rem;
      text-align: center;

      @media only screen and (max-width: $screenSize-smartphone) {
        max-width: 100%;
        padding: $smallSectionWhitespace;
      }

      h2 {
        font-size: 2.4rem;
        font-weight: 500;
        text-align: inherit;
      }

      & > p {
        text-align: inherit;
        .link {
          @media only screen and (max-width: $screenSize-smartphone) {
            display: block;
          }
        }
      }
    }

    .react-code-input {
      padding: 2rem 0px;
      display: flex !important;

      & > input[type="number"]::-webkit-inner-spin-button,
      & > input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .react-code-input.verification-code-input {
      & > input {
        width: 6rem;
        height: 6rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 500;
        border: $color-white solid 1px;
        box-shadow: 0 0 0 1px $color-grey-5;
        border-radius: 1.2rem;
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        &:not(:first-child) {
          margin-left: 2.4rem;

          @media only screen and (max-width: $screenSize-smartphone) {
            &:not(:first-child) {
              margin-left: 1rem;
            }
          }

          @media only screen and (max-width: $screenSize-iphone13mini) {
            &:not(:first-child) {
              margin-left: 2.5vw;
            }
          }
        }

        &:focus-visible {
          outline: unset;
          border: $color-light-blue solid 1px;
          box-shadow: 0 0 0 1px $color-grey-5;
        }

        @media only screen and (max-width: $screenSize-smartphone) {
          width: 4.5rem;
          height: 4.5rem;
        }
      }
    }

    .new-code-container {
      width: 48rem;
      max-width: 100%;
      margin: 1rem;
      padding: 2rem;
      text-align: center;
      box-sizing: border-box;
      border-radius: 12px;
      background-color: $color-light-grey;
      font-size: 1.6rem;

      @media only screen and (max-width: $screenSize-smartphone) {
        text-align: left;
        align-items: flex-start;
      }

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &:before {
        content: url(../../../assets/icons/info.svg);
        width: 2rem;
        height: 2rem;
        display: inline-block;
        vertical-align: -0.4rem;
        margin-right: 1.6rem;
      }

      & > p {
        padding-top: 5px;

        @media only screen and (max-width: $screenSize-smartphone) {
          padding-top: unset;
        }
      }

      .link {
        display: inline-block;
      }
    }
  }
}

.react-code-input.verification-code-input > input {
  border: 1px solid $color-grey !important;

  &:focus-visible {
    outline: unset;
    border: 1px solid #657df1 !important;
    box-shadow: 0 0 0 1px #e3e8fd
  }
}