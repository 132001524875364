@import "../../../styles/variables.scss";

.App .section--hero {
  @media only screen and (max-width: $screenSize-smartphone) {
    min-height: unset;

    // padding-bottom: 118vw;
  }

  & > .section__innerWrapper {
    position: relative;
    color: $color-white;
    text-shadow: $textShadow;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: unset;
  }

  video.heroVideo {
    width: 100%;

    // height: calc( 100vh - 7.5rem);
    // max-height: 1080px;
    // height: calc(108rem - 7.5rem);
    object-fit: cover;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      height: calc(108rem - 7.5rem);
    }

    @media only screen and (max-width: $screenSize-1080p) and (min-aspect-ratio: 4 / 3) and (min-height: $screenSize-mac14) {
      height: calc(100vh - 7.5rem);
      max-height: 108rem;
    }

    // @media only screen and (max-width: $screenSize-1080p) and (max-height: 100rem) and (min-height: $screenSize-mac14) {
    @media only screen and (max-width: $screenSize-1080p) and (max-height: 100rem) and (min-width: $screenSize-mac14) {
      min-height: 108rem;
    }

    // @media only screen and (max-width: $screenSize-1080p) and (min-aspect-ratio: 4/3) {
    //   height: unset;
    //   max-height: 1080px;
    // }

    @media only screen and (max-width: $screenSize-smartphone) {
      padding-bottom: 7vw;
      object-fit: cover;
      min-height: 50vw;
      max-height: 100vh;
      height: 36.5rem; //38.2rem;
      order: 1;
    }
  }

  article {
    position: absolute;
    max-width: 60rem;
    padding-bottom: 10rem;
    padding-left: $defaultSectionWhitespace;
    padding-right: unset;
    z-index: 10;

    @media only screen and (max-width: $screenSize-1080p) {
      padding-bottom: 5.375vw;
      max-width: 28.25vw; //33.25vw;//31.25vw;
      padding-left: 10.6rem;
    }

    @media only screen and (max-width: $screenSize-tablet) {
      padding-left: 6.5rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      position: relative;
      max-width: 90vw; //35rem;
      padding-top: unset;
      padding-bottom: unset;
      order: 2;
    }

    @media only screen and (max-width: $screenSize-iphone13ProMax) {
      padding-left: $smallSectionWhitespace;
      padding-right: $smallSectionWhitespace;
    }
  }

  h1 {
    // font-size: 6rem;
    color: $color-white;
    font-size: 60px;
    font-weight: 500;
    line-height: 1.2;
    text-shadow: 0px 4px 15px rgba(11, 19, 41, 0.05);
    max-width: 450px;
    margin-bottom: 20px;

    @media only screen and (max-width: $screenSize-smartphone) {
      font-size: 40px;
      display: block;
      max-width: 100%;
    }
  }

  p {
    font-family: "Halcom W00 Medium", "League Spartan", sans-serif !important;
    font-size: 30px;
    font-weight: 500;
    line-height: $default-line-height;
    color: inherit;
    white-space: pre-wrap;
  }

  .decorations {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    // & * {
    //   transition: all 1s ease-in-out;
    // }
 
  }

  .deco1 {
    background-color: $color-light-blue;
    z-index: 0;
  }

  .deco1
  , .deco5 {
    position: absolute;
    bottom: -59.5rem;
    left: -36.5rem;
    display: block;
    width: 116.8rem;
    height: 116.8rem;

    // width: 136.8rem;
    // height: 136.8rem;
    border-radius: 50%;

    // transition: all ease-in-out 0.3s;

    @media only screen and (max-width: $screenSize-1080p) {
      bottom: -35vw;
      left: -14vw;
      width: 61.25vw;
      height: 61.25vw;
    }

    @media only screen and (min-width: $screenSize-iphone13ProMax) {
      // bottom: -25vw;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      // min-width: 97.5rem;
      width: 250vw;
      max-width: 185rem;
      height: 105rem;

      // height: 117.5rem;
      // height: 97.5rem;
      // height: 250vw;

      // left: 50%;
      // transform: translate(-50%, 50%);
      bottom: -7.75rem;
      left: calc(50% - (250vw / 2));
      bottom: calc( -7.75rem - (97.5rem / 2) );
    }

    @media only screen and (max-width: $screenSize-iphone13ProMax) {
      // bottom: -9.75rem;
      bottom: calc( -9.75rem - (97.5rem / 2) );
    }
  }

  .deco3,
  .deco1 .deco1__1 {
    position: absolute;

    // top: 9.5rem;
    // left: -17.5rem;

    background-color: $color-yellow;
    border-radius: 50%;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      // top: -8rem;
      bottom: 40rem;
      left: -17.68rem;
      width: 35rem;
      height: 35rem;
    }

    @media only screen and (max-width: $screenSize-1080p) {
      bottom: 16vw;
      left: -8.1vw;
      width: 18.2vw;
      height: 18.2vw;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      top: auto;
      bottom: 38.6rem;

      // bottom: 51.6rem;
      // bottom: 31.6rem;
      // left: -10vw; //-3.9rem;
      left: calc(50% - 60vw);
    }

    @media only screen and (max-width: $screenSize-iphone13ProMax) {
      bottom: 38.6rem;
    }

    @media only screen and (max-width: $screenSize-iphone13) {
      bottom: 39.2rem;
    }

    @media only screen and (max-width: $screenSize-iphone13mini) {
      bottom: 39.5rem;
    }
  }

  & .deco1 {
    overflow: hidden;
    z-index: 2;

    & .deco1__1 {
      content: "";
      display: block;
      background-color: $color-blue;
      z-index: 10;
    }

    & .deco1__1 {
      @media only screen and (min-width: $screenSize-1080p-plus) {
        left: 19rem;
        bottom: 99.5rem;
      }

      @media only screen and (max-width: $screenSize-1080p) {
        left: 5.89vw;
        bottom: 51.3vw;
      }

      @media only screen and (max-width: $screenSize-smartphone) {
        left: 51.9%;
        margin-left: -60vw;
        bottom: unset;
        top: -4%;
      }

      @media only screen and (max-width: $screenSize-iphone13ProMax) {
        // left: 65vw;
        left: 50%;
        margin-left: -60vw;

        // bottom: 231vw;
        bottom: unset;
        top: 0;
      }
    }
  }

  & .deco1 > .deco1__2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & .deco1 > .deco1__2::before,
  & .deco5::before {
    // content: '';
    left: 110rem;
    background-color: $color-yellow;
    position: absolute;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      bottom: 40.5rem;
    }

    @media only screen and (max-width: $screenSize-1080p) {
      left: 57.94vw;
      bottom: 26vw;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      left: 163vw;
      bottom: 106rem;

      // bottom: 87rem;
    }

    @media only screen and (max-width: $screenSize-iphone13ProMax) {
      left: 50%;
      margin-left: 39vw;
    }
  }

  .deco2 {
    display: block;
    width: 3.7rem;
    border: 4px solid $color-yellow;
    height: 3.7rem;
    position: absolute;
    bottom: 73rem;
    left: 24%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-sizing: border-box;
    background: transparent;
    z-index: 10;

    @media only screen and (max-width: $screenSize-1080p) {
      bottom: 37vw;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      bottom: 39rem;
    }
  }

  // .deco3,
  // .deco1::before {
  // animation: grow120 6s infinite linear alternate;
  // }

  .deco3 {
    mix-blend-mode: hard-light;
    opacity: 0.9;
  }

  .deco4 {
    background-color: $color-light-blue;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    box-sizing: border-box;
    position: absolute;
    left: 7%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-sizing: border-box;

    @media only screen and (min-width: $screenSize-1080p-plus) {
      top: 10.5rem;
    }

    @media only screen and (max-width: $screenSize-1080p) {
      top: 5.5vw;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      top: 3rem;
      width: 0.9rem;
      height: 0.9rem;
      left: 75vw;
    }
  }

  .deco5 {
    mix-blend-mode: hard-light;
    opacity: 0.9;
  }

  .deco5::before {
    // bottom: -19rem;
    // left: 80.64rem;
    background-color: $color-blue;

    // @media only screen and (max-width: $screenSize-1080p) {
    //   bottom: -9.9vw;
    //   left: 42vw;
    // }

    // @media only screen and (max-width: $screenSize-smartphone) {
    //   left: 89vw;
    //   top: auto;//30rem;
    //   bottom: 28.5rem;
    // }

    // @media only screen and (max-width: $screenSize-iphone13ProMax) {
    //   left: 34rem;
    // }
 
  }

  //  *** temp
  .deco5::before,
  .deco1 .deco1__2::before {
    content: "";
    position: absolute;
    width: 37.4rem;
    height: 37.4rem;
    border-radius: 50%;

    // animation: grow150 20s alternate linear infinite;

    @media only screen and (max-width: $screenSize-1080p) {
      width: 19.47vw;
      height: 19.47vw;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      width: 13rem;
      height: 13rem;
      top: -4rem;
    }
  }

  .deco6 {
    position: absolute;
    top: 15rem; //5rem;
    right: -8rem;
    width: 16.7rem;
    height: 16.7rem;
    background-color: $color-blue;
    border-radius: 50%;
    mix-blend-mode: hard-light;
    opacity: 0.9;

    // animation: grow150 6s infinite linear alternate,
    //   fadeInOut 6s alternate linear infinite;

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }

  .deco7 {
    position: absolute;
    top: 30.5rem; //6.6rem;
    right: 5rem;
    width: 3.6rem;
    height: 3.6rem;
    background-color: $color-yellow;
    border-radius: 50%;

    // animation: grow200 6s infinite ease-in-out alternate;

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }
}


// Static hero
.App .section--hero.static--hero {
  position: relative;
  width: 100%;
  height: 800px;
  @media screen and (max-height: 900px) and (orientation: landscape) {
    height: 600px;
  }
  .section__innerWrapper {
    height: 100%;
    justify-content: center;
    &:after {
      content: '';
      background: rgba(33, 43, 61, 0.37);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  article {
    position: relative;
    top: auto;
    padding: 0;
    // max-width: 1920px;
    padding-right: 10.6rem;
    padding-left: 10.6rem;
    max-width: 530px;


    h1 {
      font-size: 5.4rem;
    }

    p {
      font-size: 1.9rem;
    }

    @media screen and (max-width: $screenSize-smartphone) {
      padding-left: 20px;
      padding-right: 20px;

      h1 {
        font-size: 3.4rem;
      }
  
      p {
        font-size: 1.8rem;
      }
    }

    a {
      display: inline-block;
      margin-top: 40px;
      text-decoration: none;
    }
  }
}

.bubbles-top {
  position: absolute;
  top: 0;
  right: 50px;
  z-index: 1;
}

.bubbles-bottom {
  position: absolute;
  bottom: 50px;
  left: 0;
  z-index: 1;
}

@media screen and (max-width: $screenSize-smartphone) {
  .bubbles-top, .bubbles-bottom {
    display: none;
  }
}