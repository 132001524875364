@import "../../../styles/variables.scss";

.upload-container {
  background: #f8f9ff;
  border-radius: 100px;
  padding: 1.5rem;
  font-size: 1rem;

  & .remove-icon {
    display: inline-block;
    content: "\00d7"; /* This will render the 'X' */
    color: black;
    fill: black;
  }
}

.file-upload {

  .box {
    padding: 8.7rem 10rem;
    width: 48rem;
    max-width: 100%;

    @media only screen and (max-width: $screenSize-smartphone) {
      padding: 8rem 5rem;
    }
  }
}
