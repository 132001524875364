@import "../../../styles/variables.scss";

.App .section--howWeCompare {
  & > .section__innerWrapper {
    background-color: $color-blue;
    color: $color-white;
    text-shadow: $textShadow;
    line-height: $default-line-height;

    // padding-left: unset;
    // padding-right: unset;

    // height: 72.2rem;

    // height: 120rem;
    display: flex;
    flex-direction: column;
    gap: 10rem;

    // justify-content: space-between;
    align-items: center;
    padding: 10rem;

    @media only screen and (max-width: $screenSize-tablet) {
      padding-right: $smallSectionWhitespace;
      padding-left: $smallSectionWhitespace;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      // height: 86.2rem;
      height: auto;
      padding-bottom: 6rem;
      padding-top: 6rem;
      margin-bottom: 0; //33rem;

      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  // onScreen animations
  & .table.animateIn > .table__body > ul > li::before,
  & .table.animateIn > .table__body > ul > li::after {
    visibility: hidden;
  }

  &.onScreen .table.animateIn > .table__body > ul > li::before,
  &.onScreen .table.animateIn > .table__body > ul > li::after {
    animation: fadeInScaleIn 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(1)::before {
    animation-delay: $animateIn-Delay-1;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(1)::after {
    animation-delay: $animateIn-Delay-1;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(2)::before {
    animation-delay: $animateIn-Delay-2;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(2)::after {
    animation-delay: $animateIn-Delay-2;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(3)::before {
    animation-delay: $animateIn-Delay-3;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(3)::after {
    animation-delay: $animateIn-Delay-3;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(4)::before {
    animation-delay: $animateIn-Delay-4;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(4)::after {
    animation-delay: $animateIn-Delay-4;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(5)::before {
    animation-delay: $animateIn-Delay-5;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(5)::after {
    animation-delay: $animateIn-Delay-5;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(6)::before {
    animation-delay: $animateIn-Delay-6;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(6)::after {
    animation-delay: $animateIn-Delay-6;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(7)::before {
    animation-delay: $animateIn-Delay-7;
  }

  &.onScreen .table.animateIn > .table__body > ul > li:nth-of-type(7)::after {
    animation-delay: $animateIn-Delay-7;
  }

  article h2.animateIn,
  article p.animateIn,
  .table {
    visibility: hidden;
  }

  &.onScreen .section__innerWrapper article h2.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-1 forwards fadeInSlideInFromBottom;
  }

  &.onScreen .section__innerWrapper article p.animateIn {
    animation: $animationDuration-short ease-in-out $animateIn-Delay-2 forwards fadeInSlideInFromBottom;
  }

  &.onScreen .section__innerWrapper .table.animateIn {
    animation: $animationDuration-medium ease-in-out $animateIn-Delay-3 forwards fadeInSlideInFromBottom;
  }

  article {
    // margin-right: $defaultSectionWhitespace;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 110rem;

    @media only screen and (max-width: $screenSize-smartphone) {
      width: auto;
    }

    h2 {
      font-size: 4rem;
      font-weight: 600;
      margin: unset;
    }

    p {
      font-family: "Halcom W00 Medium", "League Spartan", sans-serif !important;
      font-weight: 500;
      margin: unset;
      font-size: 1.6rem;
    }
  }

  .table {
    position: relative;

    // margin-top: 10.1rem;
    // margin-left: 10rem;
    // width: 95.9rem;
    max-width: 110rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    background-color: $color-white;
    color: $color-blue;
    border-radius: 4rem;
    overflow: hidden;
    box-shadow: $box-shadow;

    @media screen and (max-width: $screenSize-mac16) {
      width: 100%;

      // margin-left: 5rem;
    }

    @media screen and (max-width: $screenSize-mac13) {
      // margin-left: 2rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      // margin-top: 4rem;
      margin-left: unset;
      margin-right: unset;
      flex-shrink: 0;
    }

    &__head {
      font-family: "Halcom W00 Medium", "League Spartan", sans-serif !important;
      font-weight: 500;
      width: inherit;
      font-size: 2.4rem;
      color: $color-blue;
      text-shadow: $textShadow;
      text-align: right;
      border-bottom: 0.4rem solid $color-light-grey-2;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      @media screen and (max-width: $screenSize-tablet) {
        font-size: 1.6rem;

        // width: 100%;
        // justify-content: stretch;
      }

      & > span {
        width: 30rem;
        height: 11.8rem;
        box-sizing: border-box;
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media screen and (max-width: $screenSize-mac14) {
          // width: 25rem;
        }

        @media screen and (max-width: $screenSize-720p) {
          width: 25rem;
        }

        @media screen and (max-width: $screenSize-mac13) {
          width: 20rem;
        }

        @media screen and (max-width: $screenSize-tablet) {
          width: 11rem;
        }

        @media screen and (max-width: $screenSize-smartphone) {
          // width: 50%;
          height: 9.4rem;
        }
      }
    }

    &__body {
      width: inherit;
      font-size: 1.8rem;
      font-weight: 400;

      @media screen and (max-width: $screenSize-iphone13ProMax) {
        font-size: 1.4rem;
      }

      ul {
        list-style: none;
        margin: unset;
        padding: unset;

        & > li {
          height: 10rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: $screenSize-smartphone) {
            // flex-wrap: wrap;
            // align-items: stretch;

            height: 13rem;
          }

          & > span {
            padding-left: 6rem;
            order: 1;
            text-align: left;
            flex-grow: 1;
            padding-right: 2rem;

            @media screen and (max-width: $screenSize-smartphone) {
              padding-left: 1.6rem;
              padding-right: 1.6rem;
              min-width: 12rem;
              box-sizing: border-box;

              // max-width: 13rem;
              flex-basis: 13rem;
              width: 13rem;
            }

            // @media screen and (max-width: $screenSize-smartphone) {
            //   margin: unset;
            //   padding: 0 2rem;

            //   background-color: $color-light-grey;
            //   flex-basis: 100%;
            //   height: 50%;

            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            // }
         
          }

          // @media screen and (min-width: $screenSize-smartphone) {
          &:nth-child(even) {
            background-color: $color-light-grey;
          }

          // }

          &::before,
          &::after {
            flex-basis: 30rem;
            flex-shrink: 0;
            text-align: center;

            @media screen and (max-width: $screenSize-mac14) {
              // flex-basis: 25rem;
            }

            @media screen and (max-width: $screenSize-720p) {
              flex-basis: 25rem;
            }

            @media screen and (max-width: $screenSize-mac13) {
              flex-basis: 20rem;
            }

            @media screen and (max-width: $screenSize-tablet) {
              flex-basis: 11rem;
            }

            // @media screen and (max-width: $screenSize-smartphone) {
            //   flex-basis: 11rem;
            // }

            // @media screen and (max-width: $screenSize-smartphone) {
            //   flex-basis: 50%;
            //   height: 50%rem;

            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            // }
         
          }

          &::before {
            order: 2;
            content: url("../../../assets/images/check_circle.svg");
          }

          &.isas-green::before {
            order: 2;
            content: url("../../../assets/images/check_circle.svg");
          }

          &.isas-yellow::before {
            order: 2;
            content: url("../../../assets/images/yellow_circle.svg");
          }

          &.isas-red::before {
            order: 2;
            content: url("../../../assets/images/cancel_circle.svg");
          }

          &::after {
            order: 3;
            content: url("../../../assets/images/cancel_circle.svg");
          }

          &.trad-loan-green::after {
            order: 3;
            content: url("../../../assets/images/check_circle.svg");
          }

          &.trad-loan-yellow::after {
            order: 2;
            content: url("../../../assets/images/yellow_circle.svg");
          }

          &.trad-loan-red::after {
            order: 2;
            content: url("../../../assets/images/cancel_circle.svg");
          }
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 1.2rem;
      right: 30rem;
      z-index: 0;
      display: block;
      width: 30rem;
      height: calc(100% - 2.4rem);
      background: rgba(101, 125, 241, 0.06);
      border: 3px solid #657df1;
      border-radius: 5rem;

      @media screen and (max-width: $screenSize-mac16) {
        // width: 20rem;
        // right: 20rem;
      }

      @media screen and (max-width: $screenSize-mac14) {
        // width: 25rem;
        // right: 25rem;
      }

      @media screen and (max-width: $screenSize-720p) {
        width: 25rem;
        right: 25rem;
      }

      @media screen and (max-width: $screenSize-mac13) {
        width: 20rem;
        right: 20rem;
      }

      @media screen and (max-width: $screenSize-tablet) {
        border-radius: 2rem;
        width: 11rem;
        right: 11rem;
      }

      // @media screen and (max-width: $screenSize-smartphone) {
      //   display: none;
      // }
   
    }
  }
}
