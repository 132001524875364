@import "../../../styles/variables.scss";

.input-container.datepicker {
  .error > input {
    border-color: red !important;
    color: red;
  }

  .checkbox {
    display: flex;

    & > input {
      width: 20px;
      height: 20px;
    }

    & > label {
      padding-left: 1rem;
    }
  }

  .radio {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    width: fit-content !important;
    margin: 0.5rem 0.5rem 0.5rem 0;
    padding: 0 1.5rem;

    border: 2px solid $color-grey;
    border-radius: 20rem;

    &.checked {
      border: 2px solid $color-light-blue;
      background-color: $color-light-grey-2; //TODO: for active button
    }

    & input {
      height: 2rem;
      width: 2rem;
    }

    & label {
      margin-bottom: 0;
      padding-left: 1.5rem;
    }
  }

  label,
  input,
  select {
    text-align: left;
    font-size: 1.6rem;
    font-weight: 400;
    display: block;
    transition: all 0.1s;

    &:focus-visible {
      outline: $color-light-blue solid 2px;
    }
  }

  label {
    color: $color-blue;
    margin-bottom: 1.2rem;
    padding-left: 2.5rem;
  }

  input,
  select {
    font-size: 1.6rem;
    color: $color-black;
    width: 100%;
    height: 5.5rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    box-sizing: border-box;
    border-radius: 2.75rem;
    border: 1px solid $color-grey;
    background: unset;

    &:disabled {
      color: $color-grey-4;
      background-color: $color-light-grey;
    }
  }

  select {
    appearance: none;
    background-image: url("../../../assets/images/select_chevron.png");
    background-repeat: no-repeat;
    background-position: right 3.5rem center;
    background-size: 1.2rem;

    & > option {
      background-color: $color-white;
    }
  }

  .label-above {
    margin-bottom: 8px !important;
  }
}
