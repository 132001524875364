@import "./styles/variables.scss";
@import "./styles/animations.scss";
@import "./styles/normalize.scss";
@import "./styles/fonts.scss";

html {
  font-size: 10px;
  line-height: $default-line-height;

  // font-family: "League Spartan", sans-serif !important;
  font-family: "Halcom W00 Regular", "League Spartan", sans-serif !important;
  scroll-behavior: smooth;
  scroll-padding-top: 7.6rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: unset;
  }

  h1,
  h2,
  h3,
  h4 {
    z-index: 21;
    position: relative;
    font-family: "Halcom W00 Medium", "League Spartan", sans-serif !important;
  }

  h1 {
    font-family: "Halcom W00 Bold", "League Spartan", sans-serif !important;
  }

  h4 {
    font-size: 2.4rem;
    margin-bottom: 4rem;
  }

  h5 {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 4rem;
  }

  body {
    padding-top: 7.6rem; // header height
    font-size: $fs-normal;
    min-width: $screenSize-smartphone-small;

    &.home {
      // > #ot-sdk-cookie-policy {
      //   display: none !important;
      // }

      // .custom-persistent-icon > button {
      //   display: block !important;
      //   padding: 0 !important;
      // }
   
    }
  }

  div {
    font-size: $fs-normal;
  }

  .font-weight-medium {
    font-family: "Halcom W00 Medium", "League Spartan", sans-serif !important;
  }
}

// quickfix removed - investigate
// #root {
//   display: flex;
//   justify-content: flex-start;
//   flex-direction: column;
//   align-items: center;
// }

.App {
  font-family: "Halcom W00 Regular", "League Spartan", sans-serif !important;
  color: $color-black;

  // max-width: $containerMaxWidth; // quickfix removed
  min-width: $containerMinWidth;
  width: 100%;
  overflow: hidden;

  // @media only screen and (max-width: $screenSize-1080p-plus) {

  // }

  .overDeco {
    z-index: 20;
    position: relative;
  }

  a:link,
  a:visited,
  a:hover,
  a:active,
  span.link {
    color: $color-light-blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .flex {
    display: flex;
    flex-direction: row;

    &-column {
      display: flex;
      flex-direction: column;
    }

    &.wrap {
      flex-wrap: wrap;
    }
  }

  .align {
    &-center {
      align-items: center;
    }

    &-start {
      align-items: flex-start;
    }
  }

  .justify {
    &-center {
      justify-content: center;
    }

    &-between {
      justify-content: space-between;
    }
  }

  .text-center {
    text-align: center;
  }

  // .link {
  //   color: -webkit-link;
  //   cursor: pointer;
  //   text-decoration: underline;
  // }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  // Anchors - sticky header fix
  .anchor {
    &:hover {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }

    &:before {
      content: "";
      display: block;
      height: 7.6rem; //14rem;
      margin-top: -7.6rem; //-14rem 0 0;
      visibility: hidden;
      pointer-events: none;
    }
  }

  .full-width {
    width: 100%;
  }

  // Header -> Moved to app__Header.scss

  .section__innerWrapper {
    width: 100%;
    box-sizing: border-box;
    padding-left: $defaultSectionWhitespace;
    padding-right: $defaultSectionWhitespace;
    max-width: $containerMaxWidth;
    margin: 0 auto;

    @media only screen and (max-width: $screenSize-tablet) {
      padding-left: $smallSectionWhitespace;
      padding-right: $smallSectionWhitespace;
    }
  }

  h2.center {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    color: $color-light-blue;
  }

  h3.center {
    text-align: center;
    font-size: 4rem;
    font-weight: 600;
    color: $color-blue;

    @media only screen and (max-width: $screenSize-tablet) {
      font-size: 3rem;
    }
  }

  & > main {
    display: block;

    section {
      display: block;
    }

    // misc
    // *** 1
    .formRow {
      display: block;
      width: 100%;
      box-sizing: border-box;
      min-height: 9.1rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      // justify-content: center;
      justify-content: flex-end;

      & > .error {
        max-height: 0;
        overflow: hidden;

        // visibility: hidden;
        transition: all 0.6s ease-in;
        text-align: left;

        & > ul {
          list-style: none;
          padding: unset;
          margin: 1rem 2.4rem;
          font-size: 1.6rem;
          color: $color-orange;
        }
      }

      &.invalidInput {
        & > input {
          outline-color: $color-orange;
          border-color: $color-orange;
        }

        & > .error {
          max-height: 20rem;

          // visibility: visible;
       
        }
      }
    }

    .formRow:not(:last-of-type) {
      margin-bottom: 0.7rem;
    }
  }
}
