@import "../../../styles/variables";

.App .dashboard-data-section {
  max-width: 1920px;
  padding-left: 10.6rem;
  padding-right: 10.6rem;
  margin: 4rem auto;
  @media only screen and (max-width: $screenSize-mac14) {
    padding-left: 0;
    padding-right: 0;
  }
  .dashboard-data-container {
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .dashboard-column {
      border-right: 2px solid #EDF0FC;
      width: calc(33% - 40px);
      padding: 20px;
      @media only screen and (max-width: $screenSize-tablet) {
        border-right: none;
        border-bottom: 2px solid #EDF0FC;
        width: 100%;
        &.no-border {
          border-bottom: 0;
        }
      }
      &.no-border {
        border-right: none;
      }
      ul {
        margin: 20px 0 0;
        padding: 0;
        @media only screen and (max-width: $screenSize-tablet) {
          display: none;
          &.open {
            display: block;
          }
        }
      }
      li {
        border-bottom: 1px solid #EDF0FC;
        list-style: none;
        margin-bottom: 10px;
        padding-bottom: 15px;
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        .label {
          color: #203864;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          svg {
            width: 13px;
            margin-left: 5px;
          }
        }
        .data {
          display: block;
          color: #203864;
          font-size: 16px;
          font-weight: bold;
          line-height: 20px;
          margin-top: 5px;
        }
      }
    }
    .dashboard-bottom-row {
      border-top: 2px solid #EDF0FC;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      padding: 15px;
      width: 100%;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: $screenSize-smartphone) {
          flex-direction: column;
        }
      }
      img {
        max-width: 24px;
        margin-right: 10px;
      }
    }
  }
  .column-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #203864;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    @media only screen and (max-width: $screenSize-tablet) {
      font-size: 20px;
    }
  }

  .open-close-button {
    display: none;
    @media only screen and (max-width: $screenSize-tablet) {
      display: block;
      margin-top: 5px;
      position: relative;
    }
    &.opened {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: block;
      width: 1.7rem;
      height: 0.2rem;
      background-color: #203864;
    }
    &::after {
      background-color: #203864;
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 1.7rem;
      height: 0.2rem;
      transform: translateY(-50%) rotate(90deg);
      transition: all ease-in-out 0.2s;
    }
  }
}
