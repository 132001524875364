@import "../../../styles/variables.scss";

div.disbursement {
    padding-top: 2rem;
    padding-bottom: 8rem;

    & > h1 {
        font-size: $fs-medium;
        font-weight: 500;
        text-align: center;
    }

    & > button.primary {
        width: 30.7rem;

        @media only screen and (max-width: $screenSize-smartphone) {
            width: 100%;
        }
    }
}