@import "../../../styles/variables.scss";

.authorisation-decorations {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 192rem;
  min-height: 100%;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);

  & .circle {
    color: transparent;
    border-radius: 50%;
    background-color: currentColor;

    &.light-blue {
      color: $color-light-blue;
    }

    &.yellow {
      color: $color-yellow;
    }

    &.blue {
      color: $color-blue;
    }

    &.black {
      color: $color-black;
    }

    &.outline {
      border: 0.3rem solid currentColor;
      background-color: unset;
    }
  }

  & .half {
    overflow: hidden;
    border-radius: unset;
  }

  & > * {
    max-width: 100%;
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
  }

  & > .d1 {
    position: relative;
    height: 50vh;
    border-radius: unset;
    z-index: 1;

    & > * {
      border-radius: 50%;
      position: absolute;
    }

    & > .big.blue {
      width: 316rem;
      height: 294rem;
      bottom: 0rem;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;

      & > * {
        // border-radius: inherit;
        position: absolute;
      }

      & > .yellow {
        // left: calc(50% - 53vw);
        bottom: 8vw;

        @media only screen and (max-width: $screenSize-mac16) {
          bottom: 3vw;
        }

        @media only screen and (max-width: $screenSize-tablet) {
          bottom: -5vw;
        }
      }

      & > .light-blue {
        bottom: 13rem;

        @media only screen and (max-width: $screenSize-mac16) {
          bottom: 3vw;
        }

        @media only screen and (max-width: $screenSize-tablet) {
          bottom: -5vw;
        }
      }
    }
  }

  & > .d1 > .big.blue > .yellow,
  & > .d2 {
    width: 22.7rem;
    height: 22.7rem;
    left: calc(50% - 53vw);

    @media only screen and (min-width: $screenSize-1080p-plus) {
      left: calc(50% - 107rem);
    }
  }

  & > .d2 {
    transform: translateY(-100%);
    top: calc(50vh - 8vw);

    @media only screen and (max-width: $screenSize-mac16) {
      top: calc(50vh - 3vw);
    }

    @media only screen and (max-width: $screenSize-tablet) {
      top: calc(50vh + 5vw);
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }

  & > .d1 > .big.blue.circle > .light-blue.circle,
  & > .d3 {
    width: 11.8rem;
    height: 11.8rem;
    right: calc(50% - 45vw);

    @media only screen and (min-width: $screenSize-1080p-plus) {
      right: calc(50% - 86.4rem);
    }
  }

  & > .d3 {
    transform: translateY(-100%);
    top: calc(50vh - 13rem);

    @media only screen and (max-width: $screenSize-mac16) {
      top: calc(50vh - 3vw);
    }

    @media only screen and (max-width: $screenSize-tablet) {
      top: calc(50vh + 5vw);
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }

  & > .d1 > .big.blue > .yellow,
  & > .d2,
  & > .d1 > .big.blue.circle > .light-blue.circle,
  & > .d3 {
    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }

  & > .d4.half {
    width: 1.3rem;
    height: 2.7rem;
    transform: rotate(-46deg);
    top: 8rem;
    left: calc(50% - 30vw);
    z-index: 2;

    @media only screen and (max-width: $screenSize-smartphone) {
      top: 6rem;
      left: 2rem;
      width: 1rem;
    }

    & > .circle {
      width: 2.7rem;
      height: 2.7rem;

      @media only screen and (max-width: $screenSize-smartphone) {
        width: 1.7rem;
        height: 1.7rem;
      }
    }
  }

  & > .d5.half {
    width: 1.1rem;
    height: 2.1rem;
    transform: rotate(-90deg);
    top: 60vh;
    right: calc(50% - 30vw);
    z-index: 2;

    @media only screen and (max-width: $screenSize-tablet) {
      top: 75vh;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }

    & > .circle {
      width: 2.1rem;
      height: 2.1rem;
    }
  }

  & > .d6 {
    width: 5.5rem;
    height: 5.5rem;
    left: 68%;
    bottom: 5rem;

    @media only screen and (max-width: $screenSize-smartphone) {
      width: 4.6rem;
      height: 4.6rem;
      left: unset;
      right: 3.3rem;
      bottom: -2.4rem;
    }
  }

  & > .d7 {
    width: 3.1rem;
    height: 3.1rem;
    left: 4.5%;
    bottom: 13rem;

    @media only screen and (max-width: $screenSize-smartphone) {
      &.outline {
        background-color: currentColor;
        border: none;
      }

      width: 0.9rem;
      height: 0.9rem;
      bottom: 4rem;
      left: 3.2rem;
    }
  }

  & > .d8 {
    width: 3.1rem;
    height: 3.1rem;
    right: 25.8%;
    top: 8rem;
    z-index: 1;

    @media only screen and (max-width: $screenSize-smartphone) {
      &.outline {
        background-color: currentColor;
        border: none;
      }

      &.light-blue {
        color: $color-yellow;
      }

      width: 1.2rem;
      height: 1.2rem;
      top: 12.8rem;
      right: 36%;
    }
  }

  & > .d9 {
    width: 0.9rem;
    height: 0.9rem;
    right: 6rem;
    top: 50%;
    z-index: 1;

    @media only screen and (max-width: $screenSize-tablet) {
      top: 20%;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      width: 0.9rem;
      height: 0.9rem;
      top: 9.0rem;
      right: 3.2rem;
    }
  }
}
