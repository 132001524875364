@import "../../styles/variables.scss";

.related-articles { 
  .related-articles__section-title {
    color: $color-blue;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  .related-articles__container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 60px;
  }
}