@import "../../styles/variables.scss";

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.MuiMenuItem-root {
  font-family: $font-family;
  padding: 1rem;
}

.MuiList-root {
  & > li {
    padding: 1rem 1.5rem;
  }

  & .option-label {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
}
