@import "../../../styles/variables.scss";

button:not(.accordion-button, .MuiIconButton-root) {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-radius: 20rem;

  height: 4.5rem;
  //line-height: 1;//4.5rem;
  // height: 5.5rem;
  // line-height: 5.5rem;
  

  justify-content: center;
  text-align: center;

  background-color: transparent;
  color: $color-blue;

  font-size: 1.6rem;

  border: unset;

  display: flex;
  align-items: center;

  text-decoration: none;
  position: relative;
  overflow: hidden;

  box-sizing: border-box;

  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: $color-yellow-light;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }

  &:hover::before {
    width: 100%;
  }

  &::after {
    width: 100%;
    background-color: transparent;
    z-index: -2;
  }

  &.circle {
    background-color: $color-light-blue;
    color: white;
    border-radius: 100% !important;
    height: 45px;
    width: 45px;
    font-weight: 500;
    padding: 0 !important;
  }

  &.primary {
    &::after {
      background-color: $color-yellow;
    }

    &:hover::before,
    &:active::before {
      background-color: $color-yellow-light;
    }

    &:disabled {
      background-color: $color-light-grey;
      color: $color-grey-3;
    }
  }

  &.secondary,
  &.tertiary {
    border: 1px solid $color-yellow;

    &:hover::before,
    &:active::before {
      background-color: $color-yellow-lighter;
    }

    &:disabled {
      border-color: $color-grey;
      background-color: $color-light-grey;
      color: $color-grey-4;
    }
  }

  &.tertiary {
    border: none;
  }

  &.icon {
    & > i {
      content: "";
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 0.8rem;

      display: flex;
      align-items: center;

      &.download {
        content: url("../../../assets/images/button-icons/download.svg");
      }

      &.back {
        content: url("../../../assets/images/button-icons/chevron-back.svg");
      }
    }
  }

  &.xl {
    height: 5.5rem;
    // line-height: 5.5rem;
  }

  &.l {
    height: 4.5rem;
    // line-height: 4.5rem;
  }

  &.s {
    height: 3.6rem;
    // line-height: 3.6rem;
  }
}

#onetrust-consent-sdk {
  button {
    // background-color: $color-blue !important;
    // border-color: $color-blue !important;
    // color: #FFFFFF !important;

    // &#onetrust-pc-btn-handler {
    //   background-color: white !important;
    //   border-color: $color-blue ;
    //   color: $color-blue !important;

    // }

    &::before, &::after {
      content: none !important;
      transition: none !important;
      display: none !important;
      width: 0 !important;
    }
  }
}