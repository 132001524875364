/*
  This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts:  http://www.fonts.com
*/
        
@font-face{
  font-family:"Halcom W00 Medium";
  src:url("../../src/assets/fonts/1587404/1b584af2-4749-4520-9408-8fce94f2e5d3.woff2") format("woff2"),url("../../src/assets/fonts/1587404/5d052646-e725-46ca-84f0-3283ef9f1028.woff") format("woff");
}
@font-face{
  font-family:"Halcom W00 Regular";
  src:url("../../src/assets/fonts/1587546/cdd3ff94-988b-44c6-b08e-574f160cc0d1.woff2") format("woff2"),url("../../src/assets/fonts/1587546/0c56211a-4112-42aa-b072-e873022e0467.woff") format("woff");
}
@font-face{
  font-family:"Halcom W00 Bold";
  src:url("../../src/assets/fonts/1588725/2ab8d198-2910-441a-b1d1-9aed52f66e49.woff2") format("woff2"),url("../../src/assets/fonts/1588725/a89e1c54-66c4-4a61-b4c6-f592d444cf3a.woff") format("woff");
}