@import "../../../styles/variables.scss";

.App .section--ourFundings {
  & > .section__innerWrapper {
    // padding-left: unset;
    // padding-right: unset;

    text-align: center;
    margin-top: 13rem;
    margin-bottom: 2rem;
    text-shadow: $textShadow;
    line-height: $default-line-height;

    @media only screen and (max-width: $screenSize-smartphone) {
      margin-top: 8rem;
      margin-bottom: 0;
      padding-left: $smallSectionWhitespace;
      padding-right: $smallSectionWhitespace;
    }

    & > h3 {
      @media only screen and (max-width: $screenSize-tablet) {
        font-size: 3rem;
      } 
    }
  }

  .apply-now-button {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
    text-decoration: none;

    @media only screen and (max-width: $screenSize-tablet) {
      margin-top: 0;
    }
  }

  .apply-now-info {
    padding-top: 3rem
  }

  .cards-fundings {
    display: flex;
    justify-content: space-between;
    margin: 20rem 25rem 10rem 25rem;

    @media only screen and (max-width: $screenSize-mac14) {
      margin: 20rem 8rem 10rem 8rem;
    }

    @media only screen and (max-width: $screenSize-tablet) {
      display: flex;
      flex-direction: column;
      margin: 10rem 10rem 0 10rem;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      display: flex;
      flex-direction: column;
      margin: 10rem 4rem 0 5rem;
    }
  
    .card-fundings {
      position: relative;
      padding: 3rem 4rem 2rem 2rem;
      margin: 2rem;
      width: 30%;
      text-align: left;
      background-color: $color-light-blue;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      
      @media only screen and (max-width: $screenSize-mac14) {
        width: 100%;
      }

      @media only screen and (max-width: $screenSize-tablet) {
        margin: 8rem 0;
        right: 20px;
        padding: 3rem 4rem 2rem 2rem;
      }

      @media only screen and (max-width: $screenSize-smartphone) {
        padding: 3rem 2rem 2rem 1rem;
      }
      
      .bubble-fundings {
        position: absolute;
        transform: translateX(-50%);
        border-radius: 50%;
        background-color: $color-yellow;
        display: flex;
        justify-content: center;
        align-items: center;
        mix-blend-mode: multiply; 

        &.left {
          top: -160px;
          left: 55px;
          width: 200px;
          height: 200px;

          @media only screen and (max-width: $screenSize-mac14) {
            top: -140px;
            width: 180px;
            height: 180px;
          }
        }

        &.center {
          top: -135px;
          left: 50%;
          width: 170px;
          height: 170px;
        }

        &.right {
          top: -115px;
          left: 92%;
          width: 160px;
          height: 160px;

          @media only screen and (max-width: $screenSize-mac14) {
            left: 95%;
          }

          @media only screen and (max-width: $screenSize-tablet) {
            left: 87%;
          }
        }

        .bubble-fundings-text {
          position: relative;
          color: $color-blue;
          font-size: 2.5rem;
          font-weight: 800;
          text-align: left;
          margin-left: 1.5rem;
          line-height: 3rem;
          top: -6px;

          @media only screen and (max-width: $screenSize-tablet) {
            font-size: 2.2rem;
          }
        }
      }

      .points-fundings {
        padding-top: 1rem;
        align-items: center;
        color: $color-white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        display: flex;
        min-height: 250px;
        
        .point-fundings-item {
          font-size: 1.6rem;
          margin-top: 10px;
        }
        .point-fundings-item-colored {
          color: $color-yellow;
        }
      }
    }
  }
}
