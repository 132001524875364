@import "../../../styles/variables.scss";

.funding-application-decorations {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;

  @media only screen and (max-width: $screenSize-smartphone) {
    &::after {
      content: "";
      width: 100vw;
      height: 200vh;
      position: fixed;
      bottom: unset;
      top: 100vh;
      left: 0;
      background-color: $color-white;
    }
  }

  & .circle {
    color: transparent;
    border-radius: 50%;
    background-color: currentColor;

    &.light-blue {
      color: $color-light-blue;
    }

    &.yellow {
      color: $color-yellow;
    }

    &.blue {
      color: $color-blue;
    }

    &.black {
      color: $color-black;
    }

    &.white {
      color: $color-white;
    }

    &.outline {
      border: 0.3rem solid currentColor;
      background-color: unset;
      box-sizing: border-box;
    }
  }

  & .half {
    overflow: hidden;
    border-radius: unset;
  }

  & > * {
    // max-width: 100%;
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
  }

  & > .d1 {
    width: 316rem;
    height: 294rem;
    top: 35rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-white;

    @media only screen and (max-width: $screenSize-smartphone) {
      width: 275.6vw;
      height: 275.6vw;
      top: 20.6rem;
    }
  }

  & > .d2 {
    width: 1.7rem;
    height: 1.7rem;
    left: calc(50% - 30vw);
    top: 78rem;
    z-index: 1;

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }

  & > .d4.half {
    width: 1.3rem;
    height: 2.7rem;
    transform: rotate(-46deg);
    top: 17rem;
    left: calc(50% - 39vw);
    z-index: 2;

    @media only screen and (max-width: $screenSize-smartphone) {
      top: 2.5rem;
      left: 2.5rem;
      width: 0.58rem;
    }

    & > .circle {
      width: 2.7rem;
      height: 2.7rem;

      @media only screen and (max-width: $screenSize-smartphone) {
        width: 1.14rem;
        height: 1.14rem;
      }
    }
  }

  & > .d5.half {
    width: 1.1rem;
    height: 2.1rem;
    transform: rotate(-90deg);
    top: 103rem;
    right: calc(50% - 30vw);
    z-index: 2;

    @media only screen and (max-width: $screenSize-tablet) {
      top: 75vh;
    }

    @media only screen and (max-width: $screenSize-smartphone) {
      top: unset;
      bottom: 4rem;
    }

    & > .circle {
      width: 2.1rem;
      height: 2.1rem;

      @media only screen and (max-width: $screenSize-smartphone) {
        color: $color-yellow;
      }
    }
  }

  & > .d6 {
    width: 1.2rem;
    height: 1.2rem;
    right: calc(50% - 35vw);
    top: 41.7rem;

    @media only screen and (max-width: $screenSize-smartphone) {
      width: 0.6rem;
      height: 0.6rem;
      left: 22%;
      top: 18.6rem;
    }
  }

  & > .d7 {
    width: 3.1rem;
    height: 3.1rem;
    left: calc(50% - 38vw);
    bottom: 13rem;
    z-index: 2;

    @media only screen and (max-width: $screenSize-smartphone) {
      bottom: -33rem;
      width: 1.7rem;
      height: 1.7rem;

      &.outline {
        border-width: 0.1rem;
      }
    }
  }

  & > .d8 {
    width: 3.1rem;
    height: 3.1rem;
    right: calc(50% - 22vw);
    top: 20rem;
    z-index: 1;

    @media only screen and (max-width: $screenSize-smartphone) {
      top: 2.4rem;
      right: 3.5rem;
      width: 1.7rem;
      height: 1.7rem;

      &.outline {
        border-width: 0.1rem;
      }
    }
  }

  & > .d9 {
    width: 0.9rem;
    height: 0.9rem;
    right: calc(50% - 46.5vw);
    top: 19.2rem;
    z-index: 1;
  }

  & > .d10 {
    width: 5.5rem;
    height: 5.5rem;
    right: -2.75rem;
    top: 90rem;
    z-index: 1;
  }
}

.funding-application.p-test .funding-application-decorations {
  & > .d1 {
    display: none;
  }
}

// .funding-application.disbursement .funding-application-decorations {
//   & > .d1 {
//     top: 38.5rem;
//   }
// }

.funding-application.register .funding-application-decorations,
.funding-application.verification .funding-application-decorations,
.funding-application.school-and-program .funding-application-decorations,
.funding-application.p-test .funding-application-decorations {
  & > .d1 {
    border-radius: 0 !important;
    top: 16rem;

    @media only screen and (max-width: $screenSize-smartphone) {
      top: 20rem;
      min-height: 200vh;
    }
  }

  @media only screen and (max-width: $screenSize-smartphone) {
    &::after {
      content: unset;
      display: none;
    }
  }

  // intersection left
  & > .d11 {
    top: 96rem;
    left: -4rem;
    width: 11.8rem;
    height: 11.8rem;
    background-color: $color-blue;
    overflow: hidden;
    z-index: 1;

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }

    & > .__d1 {
      position: inherit;
      left: 6.7rem;
      top: 8rem;
      background-color: $color-yellow;
    }
  }

  & > .d12,
  & > .d11 > .__d1 {
    width: 9.6rem;
    height: 9.6rem;
    border-radius: 50%;
  }

  & > .d12 {
    top: calc(96rem + 8rem);
    left: calc(-4rem + 6.7rem);
    background-color: $color-light-blue;
    z-index: 0;

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }

  // intersection right
  & > .d13 {
    top: 61rem;
    right: 5.5rem;
    width: 6.5rem;
    height: 6.5rem;
    background-color: $color-yellow;
    overflow: hidden;
    z-index: 1;

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }

    & > .__d1 {
      position: inherit;
      left: 4rem;
      top: 2rem;
      background-color: $color-blue;
    }
  }

  & > .d14,
  & > .d13 > .__d1 {
    width: 5.2rem;
    height: 5.2rem;
    border-radius: 50%;
  }

  & > .d14 {
    top: calc(61rem + 2rem);
    right: calc(5.5rem - 2.7rem);
    background-color: $color-light-blue;
    z-index: 0;

    @media only screen and (max-width: $screenSize-smartphone) {
      display: none;
    }
  }
}

.funding-application.verification .funding-application-decorations {
  // correction needed because of a different htm structure
  margin-top: -17rem;
  height: calc(100% + 17rem);
  max-height: calc(100% + 17rem);
}
