@import "../../../styles/variables.scss";

.tabs__buttons {
  margin-top: 60px;
  .MuiTabs-scroller {
    overflow-x: auto !important;
  }
  button {
    background-color: $color-white;
    border-bottom: 2px solid $color-white;
    border-radius: 0;
    text-transform: none;
    &.Mui-selected {
      border-bottom: 2px solid $color-light-blue;
      color: $color-light-blue;
      font-size: 16px;
      font-style: normal;
      // font-weight: 600;
      line-height: 24px;
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
}
.tabs__content-contaner {
  padding-top: 50px;
  margin-bottom: 60px;
}
.tabs__load-more {
  margin: 60px auto;
}

.tabs-intro-content {
  margin-top: 40px;
}
