$color-black: #203864;
$color-blue: #203864;
$color-light-blue: #657df1;
$color-white: #ffffff;
$color-light-grey: #f8f9ff;
$color-light-grey-2: #edf0fc;
$color-light-grey-3: #f5f7ff;
$color-grey: #ced5ec;
$color-grey-2: #bec6e1;
$color-grey-3: #adb5d3;
$color-grey-4: #b7c0df;
$color-grey-5: #e3e8fd;
$color-grey-6: #7988a2;
$color-grey-7: #b9c2e0;
$color-yellow: #f9df1c;
$color-yellow-light: #fae86b;
$color-yellow-lighter: #fef8d4;
$color-orange: #fd746b;
$color-light-orange: #FFEEED;
$color-lighter-orange: #FFF2E2;
$color-dark-orange: #FF9E2E;
$color-green: #71B859;
$color-light-green: #E8F9E2;

$font-family: "League Spartan", sans-serif !important;

$box-shadow: 0px 4px 40px rgba(10, 8, 35, 0.1);
$textShadow: 0px 4px 15px rgba(11, 19, 41, 0.05);

$smallSectionWhitespace: 1.6rem;
$defaultSectionWhitespace: 10.6rem; //5.52%;

$fs-small: 1.2rem;
$fs-normal: 1.6rem;
$fs-medium: 2.4rem;
$fs-large: 4rem;

$default-line-height: 1.5;

// $screenSize-customDesktop1: 1550px;
// $screenSize-mac14Big: 1440px;

$screenSize-1080p-plus: 1921px;
$screenSize-1080p: 1920px;
$screenSize-720p: 1366px;

$screenSize-mac16: 1728px;
$screenSize-mac14: 1512px;
$screenSize-mac13: 1280px;

$screenSize-tablet: 1024px;
$screenSize-smartphone: 780px;
$screenSize-smartphone-small: 320px;
$screenSize-iphone13mini: 375px;
$screenSize-iphone13: 390px;
$screenSize-iphone13ProMax: 428px;

$containerMaxWidth: $screenSize-1080p;
$containerMinWidth: 320px;
$centeredInnerMaxWidth: 1130px;

$animateIn-Delay-1: 0.01s;
$animateIn-Delay-2: 0.015s;
$animateIn-Delay-3: 0.02s;
$animateIn-Delay-4: 0.025s;
$animateIn-Delay-5: 0.03s;
$animateIn-Delay-6: 0.035s;
$animateIn-Delay-7: 0.04s;
$animateIn-Delay-8: 0.045s;
$animateIn-Delay-9: 0.05s;
$animateIn-Delay-10: 0.055s;

$animationDuration-short: 0.1s;
$animationDuration-medium: 0.15s;
$animationDuration-long: 0.3s;
