@import "../../../styles/variables.scss";

.breadcrumb__container {
  li a, p {
    font-family: $font-family;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }
  a {
    color: $color-light-blue;
    text-decoration: none !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
  p {
    color: $color-blue;
  }
  .turnicate-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    display: inline-block;
    vertical-align: middle;
    @media screen and (min-width: $screenSize-mac13) {
      max-width: 500px;
    }
    @media screen and (min-width: $screenSize-mac14) {
      max-width: 650px;
    }
  }
  svg {
    color: $color-blue;
    font-size: 2rem;
  }
}