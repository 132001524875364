@import "../../../styles/variables.scss";

.box {
  box-sizing: border-box;
  padding: 5rem;
  border-radius: 3rem;
  z-index: 0; // buttons pseudo elements not showing fix

  &:not(.outlined) {
    background-color: #ffffff;
    box-shadow: 0px 4px 40px rgb(10 8 35 / 10%);
  }

  &.outlined {
    border: 1px solid $color-grey;

    &.dotted {
      border-style: dashed;
    }
  }

  @media only screen and (max-width: $screenSize-smartphone) {
    padding-left: $smallSectionWhitespace;
    padding-right: $smallSectionWhitespace;
  }
}
