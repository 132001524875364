@import "../../../styles/variables.scss";

.step-wrapper.step--documents {
    position: relative;
    font-size: $fs-normal;

    @media only screen and (max-width: $screenSize-smartphone) {
        padding-left: unset;
        padding-right: unset;
    }

    & > h1 {
        font-size: $fs-medium;
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-align: center;
    }

    & > div:first-of-type {
    }

    & .form-container {
        margin-top: 4rem;

        & > .flex-column.gap-3rem {
            & > *:not(:first-child) {
                margin-top: 3rem;
            }
        }

        .file-upload .box {
            background-color: $color-white;
        }
    }

    @media only screen and (max-width: $screenSize-smartphone) {
        & > button.full-width {
            width: 100%;
        }
    }
}